import React, { useState, useEffect } from 'react';
import BASE_URL from '../../Config';
import Swal from 'sweetalert2';
import { Button, ButtonGroup, CircularProgress } from '@mui/material'; 
import { Link } from "react-router-dom";

function CargaFacturacionDataico() {
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false); 
  const [loadedCount, setLoadedCount] = useState(0); 

  useEffect(() => {
   
    setLoading(false);
  }, []); 

  const handleClick = async () => {
    setLoading(true); 
    try {
      const response = await fetch(`${BASE_URL}/dataico/facturas/range`, {
        
      });
      if (!response.ok) {
        throw new Error('Error al cargar las facturas');
      }

      const data = await response.json();

      if (data.successfulBills && data.successfulBills.length > 0) {
        const totalCount = data.totalCount;
        const newLoadedCount = loadedCount + data.successfulBills.length;
        setLoadedCount(newLoadedCount);
        if (newLoadedCount === totalCount) {
          setSuccessMessage('¡Todas las facturas han sido cargadas!');
          setLoading(false); 
        }
      } else {
        setSuccessMessage('No hay facturas nuevas cargadas');
      }

      console.log('Facturas cargadas correctamente:', data);
    } catch (error) {
      console.error('Error:', error);
      setSuccessMessage(null); 
      Swal.fire('Error al cargar las facturas', error.message, 'error');
    } finally {
      setLoading(false); 
    }
  };

  const handleCloseMessage = () => {
    setSuccessMessage(null);
  };

  return (
    <div>
      <ButtonGroup
        variant="contained"
        aria-label="Basic button group"
        style={{
          position: "fixed",
          top: "calc(100px + 20px)",
          right: "20px",
          zIndex: "999"
        }}
      >
        <Link to="/homePage" className="link">
          <Button style={{margin: '10px'}}>Volver</Button>
        </Link>
      </ButtonGroup> 
      {successMessage && (
        <div>
          <p>{successMessage}</p>
          <button onClick={handleCloseMessage}>Cerrar</button>
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <ButtonGroup style={{ margin: '0 auto' }}>
          <Button variant="contained" onClick={handleClick} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Cargar Facturas Dataico'}
          </Button>
        
        </ButtonGroup>
      
      </div>
    </div>
  );
}

export default CargaFacturacionDataico;
