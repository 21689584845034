
function obtenerFechaActual() {
    const fecha = new Date();
    const año = fecha.getFullYear();
    // El mes se indexa desde 0, por lo que debes sumar 1 para obtener el mes actual
    const mes = ("0" + (fecha.getMonth() + 1)).slice(-2); 
    const dia = ("0" + fecha.getDate()).slice(-2);
    const fechaFormateada = `${dia}/${mes}/${año}`;
    return fechaFormateada;
  }
  
  export default obtenerFechaActual
  
