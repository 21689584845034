import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button,Grid, Box, ButtonGroup} from '@mui/material';
import BASE_URL from '../../Config';

const CashMovement = () => {
  const [movimientosCaja, setMovimientosCaja] = useState([]);
  const [filtroFecha, setFiltroFecha] = useState('');
  const [filtroEmpleado, setFiltroEmpleado] = useState('');

  useEffect(() => {
    fetchCajaData();
  }, []); 

  const fetchCajaData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/caja`);
      setMovimientosCaja(response.data.data);
    } catch (error) {
      console.error('Error al obtener los datos de caja:', error);
    }
  };

  const handleFechaChange = (e) => {
    setFiltroFecha(e.target.value);
  };

  const handleEmpleadoChange = (e) => {
    const value = e.target.value;
    setFiltroEmpleado(value);
  };
  

  const filtrarMovimientos = () => {
    let movimientosFiltrados = movimientosCaja;
  
    if (filtroFecha !== '') {
      movimientosFiltrados = movimientosFiltrados.filter(movimiento => movimiento.paymentDate.includes(filtroFecha));
    }
  
    if (filtroEmpleado !== '') {
      movimientosFiltrados = movimientosFiltrados.filter(movimiento => movimiento.username.toLowerCase().includes(filtroEmpleado.toLowerCase()));
    }
  
    return movimientosFiltrados;
  };
  const calcularTotal = () => {
    const importes = filtrarMovimientos().map(movimiento => movimiento.importe);
    const total = importes.reduce((acc, curr) => acc + curr, 0);
    return total;
  };

  return (
    <div>
     
      <Box mb={4} mt={4}>
      <Grid container spacing={2} alignItems="center">
      <ButtonGroup
        variant="contained"
        aria-label="Basic button group"
        style={{
          position: "fixed",
          top: "calc(100px + 5px)",
          right: "20px",
          zIndex: "999",
          marginBottom: "50px"
        }}
      >
        <Link to="/homePage" className="link">
          <Button style={{margin: '10px'}}>Volver</Button>
        </Link>
      </ButtonGroup> 
        <Grid item xs={3}>
          <TextField
            id="filtroFecha"
            label="Buscar por Fecha"
            type="date"
            value={filtroFecha}
            onChange={handleFechaChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="filtroEmpleado"
            label="Buscar por Empleado"
            value={filtroEmpleado}
            onChange={handleEmpleadoChange}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={filtrarMovimientos}>BUSCAR</Button>
        </Grid>
      </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className='form-title'>
            <TableCell style={{ fontWeight:'bold', color:'white'}}>Nombre Cajero</TableCell>
            <TableCell style={{ fontWeight:'bold',color:'white'}}>Fecha</TableCell>
              <TableCell style={{ fontWeight:'bold',color:'white'}}>Contrato N°</TableCell>
              <TableCell style={{ fontWeight:'bold',color:'white'}}>Usuario</TableCell>
              <TableCell style={{ fontWeight:'bold',color:'white'}}>Descripción</TableCell>
              <TableCell style={{ fontWeight:'bold',color:'white'}}>Método de Pago</TableCell>
              <TableCell style={{ fontWeight:'bold',color:'white'}}>Importe</TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {filtrarMovimientos().map((movimiento, index) => (
              <TableRow key={index}>
                <TableCell>{movimiento.cashierName}</TableCell>
                <TableCell>{movimiento.paymentDate}</TableCell>
                <TableCell>{movimiento.contract}</TableCell>
                <TableCell>{movimiento.username}</TableCell>
                <TableCell>{movimiento.description}</TableCell>
                <TableCell>{movimiento.paymentMethod}</TableCell>
                <TableCell>{movimiento.importe}</TableCell>
                
              </TableRow>
            ))}
             <TableRow>
              <TableCell colSpan={4}></TableCell>
              <TableCell style={{ fontWeight:'bold'}} colSpan={2}>Total</TableCell>
              <TableCell style={{ fontWeight:'bold'}}>{calcularTotal()}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CashMovement;
