import React, {useEffect, useState} from 'react';
import { Button, ButtonGroup } from "@mui/material";
import { Formik } from 'formik';
import { Link, useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import BASE_URL from '../../Config';
import Swal from 'sweetalert2';
import { fetchContractDetails } from '../../Redux/Actions/actions';

export default function ChangeContract() {
    const { n_contrato } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const contractDetails = useSelector((state) => state.contractDetails);
    
    const loading = useSelector((state) => state.loading);
    const error = useSelector((state) => state.error);
    const [formValues, setFormValues] = useState({
        n_contrato: '',
       
        estado_contrato: 'ACTIVO',
    
    });
      useEffect(() => {
        if (n_contrato) {
            dispatch(fetchContractDetails(n_contrato));
        }
    }, [dispatch, n_contrato]);

    useEffect(() => {
        if (contractDetails) {
            setFormValues({
                n_contrato: contractDetails.n_contrato,
                estado_contrato: contractDetails.estado_contrato,
            });
        }
    }, [contractDetails]);

      const  handleActualizarContrato = (values, resetForm) => {
        fetch(`${BASE_URL}/contract/modificar/${values.n_contrato}`, { 
         
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
         
          body: JSON.stringify(values)
        })
          .then(response => response.json())
          .then(data => {
            
            console.log('Respuesta del servidor:', data);
            Swal.fire({
              icon: 'success',
              title: '¡Éxito!',
              text: 'Contrato modificado correctamente.',
            }).then(() => {
                history.push("/homepage");
            });
          })
          .catch(error => console.error('Error al enviar solicitud:', error));
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Hubo un error. Por favor, inténtalo de nuevo.',
          });
        
        resetForm();
      };

      if (loading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

 return (
        <div style={{justifyContent:"center"}}>
            <ButtonGroup
                variant="contained"
                aria-label="Basic button group"
                style={{
                    position: "fixed",
                    top: "calc(100px + 20px)",
                    right: "20px",
                    zIndex: "999"
                }}
            >
                <Link to="/homePage" className="link">
                    <Button style={{ margin: '10px' }}>Volver</Button>
                </Link>
            </ButtonGroup>
            <div className='title-container'>
                <h2 className='form-title'>Actualización de comprobantes</h2>
            </div>
            <div className="formulario">
                <Formik
                    enableReinitialize
                    initialValues={formValues}
                    onSubmit={(values, { resetForm }) => handleActualizarContrato(values, resetForm)}
                >
                    {({ handleSubmit, handleChange, values }) => (
                        <form className='form-container' onSubmit={handleSubmit}>
                            <label className='label' htmlFor="n_contrato">N° de Contrato:</label>
                            <input
                                className='input-label'
                                id="n_contrato"
                                name="n_contrato"
                                type="text"
                                onChange={handleChange}
                                value={values.n_contrato}
                                readOnly
                            />
                           
                            <label className='label' htmlFor="estado_contrato">Estado Contrato</label>
                            <select
                                className='input-label'
                                id="estado_contrato"
                                name="estado_contrato"
                                onChange={handleChange}
                                value={values.estado_contrato}
                            >
                                <option value="ESPERANDO DATOS TECNICOS">Esperando Datos Técnicos</option>
                                <option value='PENDIENTE FIRMA DIGITAL'>Pendiente Firma Digital</option>
                                <option value="ACTIVO">Activo</option>
                                <option value="CORTESIA">Cortesía</option>
                                <option value="ACTIVO CONGELADO">Activo Congelado</option>
                                <option value="ACTIVO PENDIENTE DE RETIRO">Activo Pendiente de Retiro</option>
                                <option value="RETIRADO">Retirado</option>
                                <option value="CORTADO">Cortado</option>
                                <option value="ANULADO">Anulado</option>
                                <option value='PENDIENTE INSTALACION'>Pendiente Instalación</option>
                                <option value='SOLICITUD RECHAZADA'>Solicitud Rechazada</option>
                                <option value='SOLICITUD DE CONTRATO'>Solicitud de Contrato</option>
                                <option value='EN PROMOCION'>En Promoción</option>
                            </select>
                            <ButtonGroup>
                                <div className='button-container'>
                                    <Button type="submit">Enviar</Button>
                                </div>
                            </ButtonGroup>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
