import React from 'react';
import '../Vouchers/vouchers.css';
import { Button, ButtonGroup } from "@mui/material";
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Link } from "react-router-dom";
import BASE_URL from '../../Config';
import { useState } from 'react';
import Swal from 'sweetalert2';

export default function Vouchers() {
  const history = useHistory()
  const [formValues, setFormValues] = useState({
    number: '',
    tipoComprobante: 'factura',
    prefijo: '',
    inicioRango: '',
    finRango: '',
    numeracionRecibos: '',
    actualizarEmail: '',
   
  });

  const FormValuesMuni = {
    municipio: '',
    codigo_ciudad: '',
    departamento: ''
  };
  const formValuesDesRet = {
    n_contrato:"",
    nombreDescuento:'',
    porcentajeDesc:'',
    tipoRetencion:'',
    porcentajeRet:''
  };

  const formValuesModificarCon = {
    n_contrato:'',
    tel1:'',
    tel2:'',
    tel3:'',
    estado_contrato:'ACTIVO',
    idStratus:1
  }

  const formValuesRecibo = {
    numeroRecibo:''
  };
  const formValueEmail = {
    nuevoEmail:''
  };

  const FormValuePromo ={
    promo_name:'',
    descripcion:'',
    mesesDescontados:0,
    mesesPagos:0,


  }




  const handleSubmit = (values, resetForm) => {
    fetch(`${BASE_URL}/resolucion/cargar`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Respuesta del servidor:', data);
        if (data.error) {
          // Muestra un mensaje de alerta si hay un error
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: data.data.error,
          });
        } else {
          // Muestra un mensaje de alerta si la operación se ha completado correctamente
          Swal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: 'Enviado correctamente.',
          });
        }
      })
      .catch(error => console.error('Error al enviar solicitud:', error));
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error. Por favor, inténtalo de nuevo.',
      });
    resetForm();
  };

  const handleMunicipio = (values, resetForm) => {
    fetch(`${BASE_URL}/municipios/cargar`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Respuesta del servidor:', data);
        Swal.fire({
          icon: 'success',
          title: '¡Éxito!',
          text: 'Municipio agregado correctamente.',
        });
      })
      .catch(error => console.error('Error al enviar solicitud:', error));
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error. Por favor, inténtalo de nuevo.',
      });

    resetForm();
  };

  const handleDescRet = (values, resetForm) => {
    fetch(`${BASE_URL}/contract/${values.n_contrato}`, { 
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Respuesta del servidor:', data);
        Swal.fire({
          icon: 'success',
          title: '¡Éxito!',
          text: 'Municipio agregado correctamente.',
        });
      })
      .catch(error => console.error('Error al enviar solicitud:', error));
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error. Por favor, inténtalo de nuevo.',
      });

    resetForm();
  };
  
  const  handleActualizarContrato = (values, resetForm) => {
    fetch(`${BASE_URL}/contract/modificar/${values.n_contrato}`, { 
     
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
     
      body: JSON.stringify(values)
    })
      .then(response => response.json())
      .then(data => {
        
        console.log('Respuesta del servidor:', data);
        Swal.fire({
          icon: 'success',
          title: '¡Éxito!',
          text: 'Contrato modificado correctamente.',
        });
      })
      .catch(error => console.error('Error al enviar solicitud:', error));
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error. Por favor, inténtalo de nuevo.',
      });
    
    resetForm();
  };

  const handlePromo = (values, resetForm) => {
    fetch(`${BASE_URL}/promos/cargar`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Respuesta del servidor PROMOOO:', data);
        Swal.fire({
          icon: 'success',
          title: '¡Éxito!',
          text: 'Promo cargada correctamente.',
        });
      })
      .catch(error => console.error('Error al enviar solicitud:', error));
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error. Por favor, inténtalo de nuevo.',
      });

    resetForm();
  };


  return (
    
    <div className='container'>
       <ButtonGroup
        variant="contained"
        aria-label="Basic button group"
        style={{
          position: "fixed",
          top: "calc(100px + 20px)",
          right: "20px",
          zIndex: "999"
        }}
      >
        <Link to="/homePage" className="link">
          <Button style={{margin: '10px'}}>Volver</Button>
        </Link>
      </ButtonGroup> 
      <div className='title-container'>
      <h2 className='form-title'>Actualización de comprobantes</h2>
      
      </div>
      <div className="formulario">
     
        <Formik
        
          initialValues={formValues}
          onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
        >
          {({ handleSubmit, handleChange, values }) => (
            <form className='form-container' onSubmit={handleSubmit}>
            
              <h3 className='form-title'>Facturas</h3>
              <label className='label' htmlFor="number">Número de Resolución</label>
              <input
                className='input-label'
                id="number"
                name="number"
                type="text"
                onChange={handleChange}
                value={values.number}
              />

              <label className='label' htmlFor="prefijo">Prefijo</label>
              <input
                className='input-label'
                id="prefijo"
                name="prefijo"
                type="text"
                onChange={handleChange}
                value={values.prefijo}
              />

              <label className='label' htmlFor="inicioRango">Rango Desde</label>
              <input
                className='input-label'
                id="inicioRango"
                name="inicioRango"
                type="text"
                onChange={handleChange}
                value={values.inicioRango}
              />

              <label className='label' htmlFor="finRango">Rango Hasta</label>
              <input
                className='input-label'
                id="finRango"
                name="finRango"
                type="text"
                onChange={handleChange}
                value={values.finRango}
              />

              <label className='label' htmlFor="tipoComprobante">Tipo de Comprobante</label>
              <select
                className='input-label'
                id="tipoComprobante"
                name="tipoComprobante"
                onChange={handleChange}
                value={values.tipoComprobante}
              >
                <option value="factura">Factura</option>
                <option value="nota_debito">Nota Débito</option>
                <option value="nota_credito">Nota Crédito</option>
              </select>

              <ButtonGroup>
                <div className='button-container'>
                  <Button type="submit">Enviar</Button>
                </div>
              </ButtonGroup>

            </form>
          )}
        </Formik>

        <Formik
        
        initialValues={FormValuesMuni}
        onSubmit={(values, { resetForm }) => handleMunicipio(values, resetForm)}
      >
        {({handleSubmit, handleChange, values }) => (
          <form className='form-container' onSubmit={handleSubmit}>
           
            <h3 className='form-title'>Agregar Municipio</h3>
            <label className='label' htmlFor="municipio">Nombre Municipio</label>
            <input
              className='input-label'
              id="municipio"
              name="municipio"
              type="text"
              onChange={handleChange}
              value={values.municipio}
            />

            <label className='label' htmlFor="codigo_ciudad">Código Ciudad</label>
            <input
              className='input-label'
              id="codigo_ciudad"
              name="codigo_ciudad"
              type="text"
              onChange={handleChange}
              value={values.codigo_ciudad}
            />

            <label className='label' htmlFor="departamento">Departamento</label>
            <input
              className='input-label'
              id="departamento"
              name="departamento"
              type="text"
              onChange={handleChange}
              value={values.departamento}
            />

            <ButtonGroup>
              <div className='button-container'>
                <Button type="submit">Enviar</Button>
              </div>
            </ButtonGroup>

          </form>
        )}
      </Formik>

      <Formik
        
        initialValues={formValuesDesRet}
        onSubmit={(values, { resetForm }) => handleDescRet(values, resetForm)}
      >
        {({ handleSubmit, handleChange, values }) => (
          <form className='form-container' onSubmit={handleSubmit}>
          
            <h3 className='form-title'>Descuentos/Retenciones Contratos</h3>
            <label className='label' htmlFor="n_contrato">N° de Contrato:</label>
            <input
              className='input-label'
              id="n_contrato"
              name="n_contrato"
              type="text"
              onChange={handleChange}
              value={values.n_contrato}
            />
            <label className='label' htmlFor="nombreDescuento">Tipo Descuento/Prorrateo</label>
            <input
              className='input-label'
              id="nombreDescuento"
              name="nombreDescuento"
              type="text"
              onChange={handleChange}
              value={values.nombreDescuento}
            />

            <label className='label' htmlFor="porcentajeDesc">Porcentaje descuento</label>
            <input
              className='input-label'
              id="porcentajeDesc"
              name="porcentajeDesc"
              type="text"
              onChange={handleChange}
              value={values.porcentajeDesc}
            />

            <label className='label' htmlFor="tipoRetencion">Tipo de Retención</label>
            <select
              className='input-label'
              id="tipoRetencion"
              name="tipoRetencion"
              onChange={handleChange}
              value={values.tipoRetencion}
            >
             <option value="RET_FUENTE">RET_FUENTE</option>
              <option value="RET_IVA">RET_IVA</option>
              <option value="RET_ICA">RET_ICA</option>
              </select>

            <label className='label' htmlFor="porcentajeRet">Porcentaje retención</label>
            <input
              className='input-label'
              id="porcentajeRet"
              name="porcentajeRet"
              onChange={handleChange}
              value={values.porcentajeRet}
            />
             

            <ButtonGroup>
              <div className='button-container'>
                <Button type="submit">Enviar</Button>
              </div>
            </ButtonGroup>

          </form>
        )}
      </Formik>

      <Formik
        
        initialValues={formValuesModificarCon}
        onSubmit={(values, { resetForm }) => handleActualizarContrato(values, resetForm)}
      >
        {({ handleSubmit, handleChange, values }) => (
          <form className='form-container' onSubmit={handleSubmit}>
          
            <h3 className='form-title'>Actualizar Contratos</h3>
            <label className='label' htmlFor="n_contrato">N° de Contrato:</label>
            <input
              className='input-label'
              id="n_contrato"
              name="n_contrato"
              type="text"
              onChange={handleChange}
              value={values.n_contrato}
            />
            <label className='label' htmlFor="tel1">Tel 1</label>
            <input
              className='input-label'
              id="tel1"
              name="tel1"
              type="text"
              onChange={handleChange}
              value={values.tel1}
            />

<label className='label' htmlFor="tel2">Tel 2</label>
            <input
              className='input-label'
              id="tel2"
              name="tel2"
              type="text"
              onChange={handleChange}
              value={values.tel2}
            />

            <label className='label' htmlFor="tel3">Tel 3</label>
            <input
              className='input-label'
              id="tel3"
              name="tel3"
              type="text"
              onChange={handleChange}
              value={values.tel3}
            />

<label className='label' htmlFor="estado_contrato">Estado Contrato</label>
<select
  className='input-label'
  id="estado_contrato"
  name="estado_contrato"
  onChange={handleChange}
  value={values.estado_contrato}
>
  <option value="ESPERANDO DATOS TECNICOS">Esperando Datos Técnicos</option>
  <option value='PENDIENTE FIRMA DIGITAL'>Pendiente Firma Digital</option>
  <option value="ACTIVO">Activo</option>
  <option value="CORTESIA">Cortesía</option>
  <option value="ACTIVO CONGELADO">Activo Congelado</option>
  <option value="ACTIVO PENDIENTE DE RETIRO">Activo Pendiente de Retiro</option>
  <option value="RETIRADO">Retirado</option>
  <option value="CORTADO">Cortado</option>
  <option value="ANULADO">Anulado</option>
  <option value='PENDIENTE INSTALACION'>Pendiente Instalación</option>
  <option value='SOLICITUD RECHAZADA'>Solicitud Rechazada</option>
  <option value='SOLICITUD DE CONTRATO'>Solicitud de Contrato</option>
  <option value='EN PROMOCION'>En Promoción</option>
</select>

            <label className='label' htmlFor="idStratus">Estrato</label>
<select
  className='input-label'
  id="idStratus"
  name="idStratus"
  onChange={handleChange}
  value={values.idStratus}
>
  <option value="1">1</option>
  <option value="2">2</option>
  <option value="3">3</option>
  <option value="4">4</option>
  <option value="5">5</option>
  <option value="6">6</option>
</select>
              

            <ButtonGroup>
              <div className='button-container'>
                <Button type="submit">Enviar</Button>
              </div>
            </ButtonGroup>

          </form>
        )}
      </Formik>

      <Formik
        
        initialValues={formValuesRecibo}
        onSubmit={(values, { resetForm }) => handleMunicipio(values, resetForm)}
      >
        {({handleSubmit, handleChange, values }) => (
          <form className='form-container' onSubmit={handleSubmit}>
           

            {/* Tu formulario actual */}
            <h3 className='form-title'>Actualizar N° Recibo</h3>
            <label className='label' htmlFor="numeroRecibo">N° Recibos</label>
            <input
              className='input-label'
              id="numeroRecibo"
              name="numeroRecibo"
              type="text"
              onChange={handleChange}
              value={values.numeroRecibo}
            />

            <ButtonGroup>
              <div className='button-container'>
                <Button type="submit">Enviar</Button>
              </div>
            </ButtonGroup>

          </form>
        )}
      </Formik>
      <Formik
        
        initialValues={formValueEmail}
        onSubmit={(values, { resetForm }) => handleMunicipio(values, resetForm)}
      >
        {({handleSubmit, handleChange, values }) => (
          <form className='form-container' onSubmit={handleSubmit}>
           

            
            <h3 className='form-title'>Actualizar Correo electrónico</h3>
            <label className='label' htmlFor="nuevoEmail">Nuevo Email</label>
            <input
              className='input-label'
              id="nuevoEmail"
              name="nuevoEmail"
              type="text"
              onChange={handleChange}
              value={values.nuevoEmail}
            />

            <ButtonGroup>
              <div className='button-container'>
                <Button type="submit">Enviar</Button>
              </div>
            </ButtonGroup>

          </form>
        )}
      </Formik>

      <Formik
        
        initialValues={FormValuePromo}
        onSubmit={(values, { resetForm }) => handlePromo(values, resetForm)}
      >
        {({handleSubmit, handleChange, values }) => (
          <form className='form-container' onSubmit={handleSubmit}>
           
            <h3 className='form-title'>Agregar Promoción</h3>
            <label className='label' htmlFor="municipio">Nombre Promo</label>
            <input
              className='input-label'
              id="promo_name"
              name="promo_name"
              type="text"
              onChange={handleChange}
              value={values.promo_name}
            />

            <label className='label' htmlFor="codigo_ciudad">Descripción</label>
            <input
              className='input-label'
              id="descripcion"
              name="descripcion"
              type="text"
              onChange={handleChange}
              value={values.descripcion}
            />

            <label className='label' htmlFor="departamento">Meses descontados</label>
            <input
              className='input-label'
              id="mesesDescontados"
              name="mesesDescontados"
              type="text"
              onChange={handleChange}
              value={values.mesesDescontados}
            />
              <label className='label' htmlFor="departamento">Meses pagos</label>
            <input
              className='input-label'
              id="mesesPagos"
              name="mesesPagos"
              type="text"
              onChange={handleChange}
              value={values.mesesPagos}
            />

            <ButtonGroup>
              <div className='button-container'>
                <Button type="submit">Enviar</Button>
              </div>
            </ButtonGroup>

          </form>
        )}
      </Formik>

        
      </div>
    </div>
  );
};