import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import BASE_URL from '../../Config';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Alert,
  ButtonGroup,
  Button
} from '@mui/material';

export default function EstadoContratosConPromo() {
  const [promos, setPromos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();
  useEffect(() => {
    const fetchPromos = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/promos/allPromosContract`);
        setPromos(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching promos:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchPromos();
  }, []);

  return (
    <div>
      <ButtonGroup  variant="contained"
        aria-label="Basic button group"
        style={{
          position: "fixed",
          top: "calc(100px + 5px)",
          right: "20px",
          zIndex: "999",
          marginBottom: "50px",
        }}>
      <Button
                  style={{}}
                  type="button"
                  onClick={() => history.push("/homePage")}
                >
                  Volver
                </Button>
      </ButtonGroup>
      <Typography variant="h4" gutterBottom>
        Detalles de los Contratos con Promoción
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">Ocurrió un error al cargar los datos: {error.message}</Alert>
      ) : promos.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Contrato</TableCell>
                <TableCell>Promo ID</TableCell>
                <TableCell>Fecha de Inicio</TableCell>
                <TableCell>Fecha de Fin</TableCell>
                <TableCell>Promo Activa</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {promos.map((promo) => (
                <TableRow key={`${promo.n_contrato}-${promo.promo_id}`}>
                  <TableCell>{promo.n_contrato}</TableCell>
                  <TableCell>{promo.promo_id}</TableCell>
                  <TableCell>{promo.startDate}</TableCell>
                  <TableCell>{promo.endDate}</TableCell>
                  <TableCell>{promo.isPromoActive ? 'Sí' : 'No'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert severity="info">No se encontraron contratos con promoción.</Alert>
      )}
    </div>
  );
}

