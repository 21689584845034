import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { getUserByDocument } from '../../Redux/Actions/actions';
import '../../global.css';
import { Button, Grid } from '@mui/material';
import MapView from '../MapView'; 

export default function ContactClient() {
    const dispatch = useDispatch();
    const { n_documento } = useParams();
    const userInfo = useSelector(state => state.userInfo);

    useEffect(() => {
        dispatch(getUserByDocument(n_documento));
    }, [dispatch, n_documento]);

    if (!userInfo || !userInfo.data) {
        return <div>Cargando...</div>;
    }

    const clientData = userInfo.data;
    const contract = clientData.Contracts && clientData.Contracts[0];
    const delivery = contract && contract.Delivery;
    const initialCoordinates = delivery && delivery.latitude && delivery.longitude
        ? [delivery.latitude, delivery.longitude]
        : [0, 0]; 
    
    const handleCoordinatesChange = (newCoordinates) => {
        console.log('New Coordinates:', newCoordinates);
        // Aquí puedes manejar la actualización de coordenadas
    };

    const handleSaveCoordinates = (coordinates) => {
        console.log('Coordinates saved:', coordinates);
        
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item>
                    <Link to={`/facturacion/${n_documento}`}>
                        <Button variant="contained" color="primary">Factura Manual</Button>
                    </Link>
                </Grid>
                <Grid item>
                    <Link to="/clientes">
                        <Button variant="contained" color="primary">Volver</Button>
                    </Link>
                </Grid>
            </Grid>

            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', gap: '20px', marginTop: '20px' }}>
                <div>
                    <h3 className="form-title">Detalles del Cliente</h3>
                    <div className="user-table">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>Número de Documento:</th>
                                    <td>{clientData.n_documento}</td>
                                </tr>
                                <tr>
                                    <th>Nombre:</th>
                                    <td>{clientData.name_razonSocial}</td>
                                </tr>
                                <tr>
                                    <th>Email:</th>
                                    <td>{clientData.email}</td>
                                </tr>
                                <tr>
                                    <th>Teléfono 1:</th>
                                    <td>{contract ? contract.tel1 : ''}</td>
                                </tr>
                                <tr>
                                    <th>Teléfono 2:</th>
                                    <td>{contract ? contract.tel2 : ''}</td>
                                </tr>
                                <tr>
                                    <th>Teléfono 3:</th>
                                    <td>{contract ? contract.tel3 : ''}</td>
                                </tr>
                                <tr>
                                    <th>Fecha de Nacimiento:</th>
                                    <td>{clientData.fecha_cumple}</td>
                                </tr>
                                <tr>
                                    <th>Dirección:</th>
                                    <td>{delivery ? `${delivery.direccion}, ${delivery.municipio}` : ''}</td>
                                </tr>
                                <tr>
                                    <th>Coordenadas (Latitud):</th>
                                    <td>{delivery ? delivery.latitude : ''}</td>
                                </tr>
                                <tr>
                                    <th>Coordenadas (Longitud):</th>
                                    <td>{delivery ? delivery.longitude : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                
                <div style={{ width: '50%' }}>
                    <h3 className="form-title">Mapa de Ubicación</h3>
                    <MapView
                        initialCoordinates={initialCoordinates}
                        onCoordinatesChange={handleCoordinatesChange}
                        onSave={handleSaveCoordinates}
                    />
                </div>
            </div>
        </div>
    );
}


