import React, {  useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
  FaUndo,
} from 'react-icons/fa';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import '../../global.css'
import '../ClientDetail/client.css'
import {Button, ButtonGroup} from '@mui/material';
import { getUsers } from '../../Redux/Actions/actions';
import { Tooltip } from '@mui/material'

export default function ClientDetail() {
  const dispatch = useDispatch();
  const clientesData = useSelector((state) => state.usersData);
  
  const [clientes, setClientes] = useState([]);
  const [orderBy, setOrderBy] = useState('n_documento');
  const [orderAsc, setOrderAsc] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [userNotFound, setUserNotFound] = useState(false);
  const usersPerPage = 15;
 
  useEffect(() => {
    dispatch(getUsers()); // Llamar a la acción getUsers al montar el componente
  }, [dispatch]);

  useEffect(() => {
    setClientes(clientesData);
  }, [clientesData]);
  const nextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };


  
  const handleSort = (column) => {
    let newOrderAsc = true;
    if (column === orderBy) {
      newOrderAsc = !orderAsc;
      setOrderAsc(newOrderAsc);
    } else {
      setOrderBy(column);
    }
    const sortedData = _.orderBy(
      filteredClientes.length > 0 ? filteredClientes : clientes,
      [column],
      [newOrderAsc ? 'asc' : 'desc']
    );
    setFilteredClientes(sortedData);
  };

  const handleSearch = () => {
    const filteredExactMatch = clientes.filter((cliente) => {
      // Verifica si hay alguna coincidencia exacta en n_contrato
      const contractMatches = cliente.Contracts && cliente.Contracts.find(contract => contract.n_contrato === parseInt(searchText));
    
      // Si hay coincidencia exacta en n_contrato, devuelve true y se incluye el cliente en los resultados filtrados
      return contractMatches;
    });
  
    const filtered = clientes.filter((cliente) => {
      // Verifica si hay alguna coincidencia exacta en n_contrato

      // Si no hay coincidencia en n_contrato, se realiza la búsqueda en otros campos
      return (
        (cliente.name_razonSocial && cliente.name_razonSocial.toLowerCase().includes(searchText.toLowerCase())) ||
        (cliente.n_documento && cliente.n_documento.includes(searchText)) ||
        (cliente.Contracts && cliente.Contracts[0] &&
          ((cliente.Contracts[0].name_plan && cliente.Contracts[0].name_plan.toLowerCase().includes(searchText.toLowerCase())) ||
          (cliente.Contracts[0].estado_contrato && cliente.Contracts[0].estado_contrato.toLowerCase().includes(searchText.toLowerCase()))))
      );
    });
  
    const filteredResults = [...filteredExactMatch, ...filtered];
  
    setFilteredClientes(filteredResults);
    setCurrentPage(1);
    setUserNotFound(filteredResults.length === 0);
  };
  

  const handleResetSearch = () => {
    setClientes(clientesData);
    setSearchText('');
    setFilteredClientes([]);
    setCurrentPage(1);
    setUserNotFound(false);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  let renderClientes = [];

  if (filteredClientes && filteredClientes.length > 0) {
      renderClientes = filteredClientes.slice(indexOfFirstUser, indexOfLastUser);
  } else if (clientes && clientes.length > 0) {
      renderClientes = clientes.slice(indexOfFirstUser, indexOfLastUser);
  }
 

  const totalUsersCount = 
    (filteredClientes && filteredClientes.length > 0)
        ? filteredClientes.length
        : (clientes && clientes.length > 0)
            ? clientes.length
            : 0; // Si ambas son `null` o `undefined`, devuelve 0


  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalUsersCount / usersPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="client-detail-container">
     
      <div className="search-container">
        <input
          type="text"
          placeholder="Buscar por usuario, cédula, estado contrato"
          value={searchText}
          className="search-input"
          onChange={(e) => setSearchText(e.target.value)}
        />
        <ButtonGroup>
          <Button style={{margin: '10px'}} onClick={handleSearch}>Buscar</Button>
          <Button style={{margin: '10px'}} onClick={handleResetSearch}><FaUndo/></Button> 
          <Link to="/homePage">
            <Button style={{margin: '10px'}}>Volver</Button>
          </Link>
        </ButtonGroup>
      </div>
      
      {userNotFound && (
        <p className="error">Usuario no registrado, verifique nuevamente</p>
      )}
      <div className="table-container">
        <table className="user-table">
          <thead>
            <tr className='th-table-title'>
              <th onClick={() => handleSort('n_documento')} className={orderBy === 'n_documento' ? (orderAsc ? 'asc' : 'desc') : ''}>
                Cédula
                {orderBy === 'n_documento' && (
                  <span>{orderAsc ? (<span className="asc"><FaArrowAltCircleUp className="asc-icon" /> - Ascendente </span>) : (<span className="desc"><FaArrowAltCircleDown className="desc-icon" /> - Descendente </span>)}</span>
                )}
              </th>
              <th onClick={() => handleSort('name_razonSocial')} className={orderBy === 'name_razonSocial' ? (orderAsc ? 'asc' : 'desc') : ''}>
                Nombre y Apellidos / Razon social
                {orderBy === 'name_razonSocial' && (
                  <span>{orderAsc ? (<span className="asc"><FaArrowAltCircleUp className="asc-icon" /> - Ascendente </span>) : (<span className="desc"><FaArrowAltCircleDown className="desc-icon" /> - Descendente </span>)}</span>
                )}
              </th>
              <th># Contrato</th>
              <th>Plan Contratado</th>
              <th>Último Pago</th>
              <th>Estado contrato</th>
            </tr>
          </thead>
          <tbody>
    {renderClientes.map((cliente) => (
        <tr key={cliente.n_documento}>
            <td title="Puedes Ver los datos del Cliente y Hacer Factura Manual">
                {/* Utiliza Link para hacer que n_documento sea clicable y redirigir a la ruta /clientesContacto con el parámetro n_documento */}
                <Link to={`/clientesContacto/${cliente.n_documento}`}>
                    {cliente.n_documento}
                </Link>
                </td>
                <td>{cliente.name_razonSocial}</td>
                <td>
                  {cliente.Contracts && cliente.Contracts.length > 0 ? (
                    cliente.Contracts.length === 1 ? (
                      <Tooltip title="Haz Click para ver el detalle del contrato">
                    <Link to={`/contract/${cliente.Contracts[0].n_contrato}`}>
                      {cliente.Contracts[0].n_contrato}
                    </Link>
                  </Tooltip>
                    ) : (
                      <ul>
                        {cliente.Contracts.map((contract, index) => (
                          <li key={`${contract.n_contrato}-${index}`}>
                          
                            <Link to={`/contract/${contract.n_contrato}`}>{contract.n_contrato}</Link>
                          </li>
                        ))}
                      </ul>
                    )
                  ) : (
                    'Sin contrato'
                  )}
                </td>
                <td>
                  {cliente.Contracts && cliente.Contracts.length > 0 ? (
                    cliente.Contracts.length === 1 ? (
                      cliente.Contracts[0].name_plan
                    ) : (
                      <ul>
                        {cliente.Contracts.map((contract, index) => (
                          <li key={`${contract.name_plan}-${index}`}>{contract.name_plan}</li>
                        ))}
                      </ul>
                    )
                  ) : (
                    'N/A'
                  )}
                </td>
                <td>
                  {cliente.Contracts && cliente.Contracts.length > 0 ? (
                    cliente.Contracts.length === 1 ? (
                      cliente.Contracts[0].ultimo_pago
                    ) : (
                      <ul>
                        {cliente.Contracts.map((contract, index) => (
                          <li key={`${contract.ultimo_pago}-${index}`}>{contract.ultimo_pago}</li>
                        ))}
                      </ul>
                    )
                  ) : (
                    'N/A'
                  )}
                </td>
                <td>
                  {cliente.Contracts && cliente.Contracts.length > 0 ? (
                    cliente.Contracts.length === 1 ? (
                      cliente.Contracts[0].estado_contrato
                    ) : (
                      <ul>
                        {cliente.Contracts.map((contract, index) => (
                          <li key={`${contract.estado_contrato}-${index}`}>{contract.estado_contrato}</li>
                        ))}
                      </ul>
                    )
                  ) : (
                    'N/A'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-container">
  <button onClick={prevPage} disabled={currentPage === 1} className={`paginate ${currentPage === 1 ? 'active' : ''}`}>
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <BsChevronLeft style={{ width: '20px', height: '20px' }} />
    </span>
  </button>
  {/* Renderizar los números de página según la lógica de tu componente */}
  {pageNumbers.slice(currentPage - 1, currentPage + 15).map((number) => (
    <button key={number} onClick={() => paginate(number)} className={`paginate ${currentPage === number ? 'active' : ''}`}>
      <span className="page-number">{number}</span>
    </button>
  ))}
  <button onClick={nextPage} disabled={currentPage === pageNumbers.length} className={`paginate ${currentPage === pageNumbers.length ? 'active' : ''}`}>
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <BsChevronRight style={{ width: '20px', height: '20px' }} />
    </span>
  </button>
</div>
      </div>
      
    </div>
  );
}
