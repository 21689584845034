import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import SignIn from "./components/SignIn/SignIn";
import Navbar from "./components/Navbar/Navbar";
import HomePage from "./Pages/HomePage";
import Vouchers from "./Pages/Vouchers/Vouchers";
import MovementsCash from "./Pages/MovementsCash.js";
import TaskPage from "./Pages/TaskPage";
import Worker from "./Pages/Worker.js";
import Calendary from "./components/Calendary/Calendary";
import FormCreateWorker from "./components/FormsWorkers/FormCreateWorker.js";
import FormUpdateUser from "./components/FormsWorkers/FormUpdateUser.js";
import "../src/global.css";
import ClientDetail from "./components/ClientDetail/ClientDetail.js";
import ContractDetail from "./components/ClientDetail/ContractDetail.js";
import FacturacionManual from "./components/Facturacion/FacturacionManual.js";
import CargaFacturacionDataico from "./components/Facturacion/CargaFacturacionDataico.js";
import CashMovement from "./components/CashMovement/CashMovement.js";
import ContratosPendientes from "./components/ClientDetail/ContratosPendientes.js";
import ContactClient from "./components/ClientDetail/ContactClient.js";
import Tycketera from "./components/Ticketera/Tycketera.js";
import ModificarPromo from "./components/ModificarPromo.js";
import EstadoPromo from "./components/EstadoPromo/EstadoPromo.js";
import TodosLosContratosConPromo from "./components/EstadoPromo/TodosLosContratosConPromo"
import Masiva from "./components/Facturacion/Masiva.js"
import ChangeContract from "./components/ClientDetail/ChangeContract.js";


const PrivateRoute = ({ children, ...rest }) => {
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const userRole = useSelector((state) => state.authentication.user?.id_role);

  if (!isAuthenticated || userRole !== 4) {
    return <Redirect to="/" />;
  }

  return <Route {...rest}>{children}</Route>;
};

const App = () => {
  return (
    <BrowserRouter>
      <div className="container">
        <Navbar />
        <Switch>
          <Route exact path="/">
            <SignIn />
          </Route>

          <PrivateRoute path="/tareas">
            <TaskPage />
          </PrivateRoute>
          <PrivateRoute path="/calendario">
            <Calendary />
          </PrivateRoute>
          <PrivateRoute path="/comprobantes">
            <Vouchers />
          </PrivateRoute>
          <PrivateRoute path="/caja">
            <MovementsCash />
          </PrivateRoute>
          <PrivateRoute path="/tycketera">
            <Tycketera />
          </PrivateRoute>
          <PrivateRoute path="/movimientosCaja">
            <CashMovement />
          </PrivateRoute>
          <PrivateRoute path="/modificarPromo">
            <ModificarPromo />
          </PrivateRoute>
          <PrivateRoute path="/estadoPromocion/:n_contrato">
            <EstadoPromo />
          </PrivateRoute>
          <PrivateRoute path="/promocionesVigentes">
            <TodosLosContratosConPromo/>
          </PrivateRoute>
          <PrivateRoute
            path="/facturacion/:n_documento"
            component={FacturacionManual}
          ></PrivateRoute>
          <PrivateRoute path="/cargaFacturacion">
            <CargaFacturacionDataico />
          </PrivateRoute>
          <PrivateRoute path="/facturacion-masiva">
            <Masiva />
          </PrivateRoute>

          <PrivateRoute>
            <div style={{ flex: 3 }}>
              <Switch>
                <PrivateRoute path="/homePage">
                  <HomePage />
                </PrivateRoute>
                <PrivateRoute path="/empleados">
                  <Worker />
                </PrivateRoute>
                <PrivateRoute path='/clientes'>
                  <ClientDetail/>
                </PrivateRoute>
                <PrivateRoute path="/clientesContacto/:n_documento" component={ContactClient}/>
                

                <PrivateRoute path="/contract/:n_contrato" component={ContractDetail} >
                  </PrivateRoute>
                <PrivateRoute path="/cambiosEnContrato/:n_contrato" component={ChangeContract} >
                </PrivateRoute>
                <PrivateRoute
                  path="/clientesContacto/:n_documento"
                  component={ContactClient}
                />

                <PrivateRoute
                  path="/contract/:n_contrato"
                  component={ContractDetail}
                ></PrivateRoute>
                <PrivateRoute path="/contratosPendientes">
                  <ContratosPendientes />
                </PrivateRoute>
                <PrivateRoute path="/altaEmpleado">
                  <FormCreateWorker />
                </PrivateRoute>
                <PrivateRoute path="/modificarEmpleado">
                  <FormUpdateUser />
                </PrivateRoute>
              </Switch>
            </div>
          </PrivateRoute>
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default App;
