import BASE_URL from "../../Config";
import axios from "axios";

import {
  SIGNIN_USER,
  LOGOUT_USER,
  CLEAN_DETAIL,
  CREATE_USER,
  GET_USERS,
  FETCH_ASSIGNED_TASKS_REQUEST,
  FETCH_ASSIGNED_TASKS_SUCCESS,
  FETCH_ASSIGNED_TASKS_FAILURE,
  ASSIGN_TASK_TO_USER_REQUEST,
  ASSIGN_TASK_TO_USER_SUCCESS,
  ASSIGN_TASK_TO_USER_FAILURE,
  DELETE_TASK_FAILURE,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_REQUEST,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  GET_TASKS_REQUEST,
  GET_TASKS_FAILURE,
  GET_TASKS_SUCCESS,
  FETCH_CONTRACT_DETAILS_REQUEST,
  FETCH_CONTRACT_DETAILS_SUCCESS,
  FETCH_CONTRACT_DETAILS_FAILURE,
  CLEAN_CONTRACT_DETAILS,
  GET_USER_INFO,
  SET_ULTIMA_FACTURA,
  SET_LAST_RESOLUCION,
  FETCH_ARTICULOS_SUCCESS,
  FETCH_ARTICULOS_FAILURE,
  GET_TICKETS_REQUEST,
  GET_TICKETS_SUCCESS,
  GET_TICKETS_FAILURE,
  GET_PROMOS_REQUEST,
  GET_PROMOS_SUCCESS,
  GET_PROMOS_FAILURE,
  UPDATE_PROMO_STATUS_REQUEST,
  UPDATE_PROMO_STATUS_SUCCESS,
  UPDATE_PROMO_STATUS_FAILURE,
  GET_CONTRACT_PROMOS, 
  GET_CONTRACT_PROMOS_SUCCESS, 
  GET_CONTRACT_PROMOS_FAILURE

} from "./actions-types";

export const signInUser = (token, user) => ({
  type: SIGNIN_USER,
  payload: { token, user },
});

export const userInfo = (input) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}/authorization/login`, input);

    if (response.data.data && response.data.data.token) {
      const { token, user } = response.data.data;

      // Almacenar el token en el estado global de Redux
      dispatch(signInUser(token, user));
    } else {
    }
    
  } catch (error) {
    console.error("Error en la solicitud:", error);

    return dispatch(cleanDetail());
  }
};

export const logout = () => async (dispatch) => {
  try {
    const userLogout = { message: "User is logout" };
    dispatch({ type: LOGOUT_USER, payload: userLogout });
    dispatch(cleanDetail());
    
  } catch (error) {
    console.error("Error: ", error);
  }
};

export const getUsers = () => async (dispatch) => {
  try {

    const response = await axios.get(`${BASE_URL}/roles`);
   
    dispatch({ type: GET_USERS, payload: response.data.data.users });
  } catch (error) {
    console.error("Error al obtener usuarios:", error);
  }
};

//user por doc
export const getUserByDocument = (n_documento) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/roles/${n_documento}`);

    dispatch({ type: GET_USER_INFO, payload: data });
  } catch (error) {
    console.error("Error fetching user info:", error);
  }
};

export const createUser = (input) => async (dispatch) => {
  try {
    
    const { data } = await axios.post(`${BASE_URL}/authorization/signup`, input);
    dispatch({ type: CREATE_USER, payload: data });
    return { success: true };
  } catch (error) {
    return { success: false, errorMessage: error.message };
  }
};

export const deleteTask = (id) => async (dispatch) => {
  try {
   
    dispatch({ type: DELETE_TASK_REQUEST });
    await axios.delete(`${BASE_URL}/task/eliminar/${id}`);

    dispatch({ type: DELETE_TASK_SUCCESS });
    dispatch(fetchAssignedTasks());
    return { success: true };
  } catch (error) {
    dispatch({ type: DELETE_TASK_FAILURE, payload: error.message });
    return { success: false, errorMessage: error.message };
  }
};

export const updateUser = (n_documento, user) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_REQUEST });
    const { data } = await axios.put(
      `${BASE_URL}/roles/update/${n_documento}`,
      user
    );

    dispatch({ type: UPDATE_USER_SUCCESS, payload: data });
    dispatch(getUsers(n_documento));
   
    return { success: true };
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: error.response.data.message,
    }); // Ajusta aquí para obtener el mensaje de error del objeto de respuesta
    return { success: false, errorMessage: error.message };
  }
};

export const cleanDetail = () => {
  try {
    return {
      type: CLEAN_DETAIL,
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAssignedTasks =
  (n_documento = null) =>
  async (dispatch) => {
    dispatch({ type: FETCH_ASSIGNED_TASKS_REQUEST });
    try {
      let url = `${BASE_URL}/task/listarTareas/`;
      if (n_documento) {
        url = `${BASE_URL}/task/listarTareas/${n_documento}`;
      }
      const response = await axios.get(url);
      
      dispatch({ type: FETCH_ASSIGNED_TASKS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error("Error in fetchAssignedTasks:", error);
      dispatch({ type: FETCH_ASSIGNED_TASKS_FAILURE, payload: error.message });
    }
  };
export const assignTaskToUser =
  (taskId, n_documento, startTurno, endTurno, taskDate, taskDescription) => async (dispatch) => {
    dispatch({ type: ASSIGN_TASK_TO_USER_REQUEST });
    try {
      const response = await axios.post(`${BASE_URL}/task/asignar`, {
        taskId,
        n_documento,
        startTurno,
        endTurno,
        taskDate,
        taskDescription,
      });
     
      dispatch({ type: ASSIGN_TASK_TO_USER_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ASSIGN_TASK_TO_USER_FAILURE, payload: error.message });
    }
  };

export const getTasks = () => async (dispatch) => {
  dispatch({ type: GET_TASKS_REQUEST });
  try {
    const response = await axios.get(`${BASE_URL}/task/`);
    dispatch({ type: GET_TASKS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_TASKS_FAILURE, payload: error.message });
  }
};
// para contract



export const fetchContractDetails = (n_contrato) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_CONTRACT_DETAILS_REQUEST });

      const response = await axios.get(`${BASE_URL}/contract/${n_contrato}`);
      const {
        init_date,
        Plan,
        Delivery,
        Vivienda,
        n_documento,
        estado_contrato,
        ultimo_pago,
        descuento,
        idStratus,
        caja_nap,
        retencion,
        marca_onu,
        mac,
        reporte_c_riesgo,
        estado_cp_correo,
        id_inventory,
        Deudas,
      } = response.data.data;

      const deuda_mes_actual = Deudas?.[0]?.deuda_mes_actual || 0;
      const saldoAnterior = Deudas?.[0]?.saldoAnterior || 0;

      dispatch({
        type: FETCH_CONTRACT_DETAILS_SUCCESS,
        payload: {
          n_contrato,
          init_date,
          Plan,
          municipio: Delivery.municipio,
          barrio_vereda: Delivery.barrio_vereda,
          direccion: Delivery.direccion,
          latitude: Delivery.latitude,
          longitude: Delivery.longitude,
          Vivienda,
          n_documento,
          estado_contrato,
          ultimo_pago,
          descuento,
          idStratus,
          caja_nap,
          retencion,
          marca_onu,
          mac,
          reporte_c_riesgo,
          estado_cp_correo,
          id_inventory,
          deuda_mes_actual,
          saldoAnterior,
        },
      });
    } catch (error) {
      console.error("Error al obtener los detalles del contrato:", error);
      dispatch({
        type: FETCH_CONTRACT_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };
};


export const cleanContract = () => ({
  type: CLEAN_CONTRACT_DETAILS,
});

export const fetchLastBillNumber = () => {
  return async (dispatch) => {
      try {
          const response = await axios.get(`${BASE_URL}/lastBill/`); 
          const lastInvoiceNumber = response.data.data.lastInvoiceNumber; 
          
          dispatch({ type: SET_ULTIMA_FACTURA, payload: lastInvoiceNumber }); 
      } catch (error) {
          console.error('Error al obtener el último número de factura:', error);
          
      }
  };
};

export const fetchLastResolution = () => {
  return async (dispatch) => {
      try {
          const response = await axios.get(`${BASE_URL}/resolucion/listar`); 
          const resoluciones = response.data.data.resoluciones; 
          const resolucionesOrdenadas = resoluciones.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          const ultimaResolucion = resolucionesOrdenadas[0];
          
          dispatch({ type: SET_LAST_RESOLUCION, payload: ultimaResolucion });
      } catch (error) {
          console.error('Error al obtener el último número de factura:', error);
          
      }
  };
};

//accion para articulos

export const fetchArticulos = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${BASE_URL}/articulos`);
      const articulos = response.data.data; // Accede al arreglo de artículos en response.data.data
      dispatch({ type: FETCH_ARTICULOS_SUCCESS, payload: articulos });
    } catch (error) {
      dispatch({ type: FETCH_ARTICULOS_FAILURE, payload: error });
    }
  };
};

export const getTickets = () => async (dispatch) => {
  dispatch({ type: GET_TICKETS_REQUEST });
  
  try {
    const response = await axios.get(`${BASE_URL}/tickets/get-all-tickets-test`);
    dispatch({ type: GET_TICKETS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_TICKETS_FAILURE, payload: error.message });
  }
};

export const getPromos = () => async (dispatch) => {
  dispatch({ type: GET_PROMOS_REQUEST });
 
  try {
    const response = await axios.get(`${BASE_URL}/promos/listar`);
    dispatch({ type: GET_PROMOS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_PROMOS_FAILURE, payload: error.message });
  }
};

export const updatePromoStatus = (promo_id, isActive) => async (dispatch) => {
  dispatch({ type: UPDATE_PROMO_STATUS_REQUEST });
  try {
      await axios.put(`${BASE_URL}/promos/modificar`, { promo_id, activar: isActive });
      dispatch({
          type: UPDATE_PROMO_STATUS_SUCCESS,
          payload: { promo_id, isActive }
      });
  } catch (error) {
      dispatch({
          type: UPDATE_PROMO_STATUS_FAILURE,
          payload: error.response ? error.response.data.error : 'Error desconocido'
      });
  }
};

export const getContractPromos = (n_contrato) => async (dispatch) => {
  dispatch({ type: GET_CONTRACT_PROMOS });

  try {
      const { data } = await axios.get(`${BASE_URL}/promos/listar/${n_contrato}`);
     
      dispatch({ type: GET_CONTRACT_PROMOS_SUCCESS, payload: data });
  } catch (error) {
      dispatch({ type: GET_CONTRACT_PROMOS_FAILURE, payload: error.message });
  }
};





