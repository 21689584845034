import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ButtonGroup,
  Button,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import BASE_URL from "../../Config";
import { BASE_FRONT, BASE_DASH } from "../../RutasConfig";

export default function ContratosPendientes() {
  const [contractPendientes, setContractPendientes] = useState([]);

  useEffect(() => {
    fetchContractData();
  }, []);

  const fetchContractData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/contract`);
      console.log(response);
      const contractsFiltered = response.data.data.filter(
        (contract) =>
          contract.estado_contrato === "SOLICITUD DE CONTRATO" ||
          contract.estado_contrato === "EN PROMOCION" ||
          contract.estado_contrato === "PENDIENTE INSTALACION"
      );
      setContractPendientes(contractsFiltered);
    } catch (error) {
      console.error("Error al obtener los datos de caja:", error);
    }
  };

  return (
    <div>
      <ButtonGroup
        variant="contained"
        aria-label="Basic button group"
        style={{
          position: "fixed",
          top: "calc(100px + 5px)",
          right: "20px",
          zIndex: "999",
          marginBottom: "50px",
        }}
      >
        <Link to="/homePage" className="link">
          <Button style={{ margin: "10px" }}>Volver</Button>
        </Link>
        <Link to="/promocionesVigentes" className="link">
          <Button style={{ margin: "10px" }}>Promos Activas</Button>
        </Link>
      </ButtonGroup>
      <h2 className="form-title">Solicitudes de Contratos</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>N° Contrato</TableCell>
              <TableCell>N° Documento</TableCell>
              <TableCell>Estado Contrato</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contractPendientes.map((contract) => (
              <TableRow key={contract.n_contrato}>
                <TableCell>
                  <Tooltip title="Puedes Ver el Plazo de la Promoción O enviar a formulario de Datos Técnicos">
                    {contract.estado_contrato === "PENDIENTE INSTALACION" ? (
                      <a
                        href={`${BASE_FRONT}/datosTecnicos/${contract.n_contrato}`}
                      >
                        {contract.n_contrato}
                      </a>
                    ) : (
                      <Link
                        to={
                          contract.estado_contrato === "EN PROMOCION"
                            ? `/estadoPromocion/${contract.n_contrato}`
                            : `/contract/${contract.n_contrato}`
                        }
                      >
                        {contract.n_contrato}
                      </Link>
                    )}
                  </Tooltip>
                </TableCell>

                <TableCell>
                  <Tooltip title="Haz click para Facturar La Promo">
                    <Link to={`/clientesContacto/${contract.n_documento}`}>
                      {contract.n_documento}
                    </Link>
                  </Tooltip>
                </TableCell>
                <TableCell>{contract.estado_contrato}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
