import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import style from './SignIn.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { userInfo } from '../../Redux/Actions/actions';
import { login } from './funcs';
import { Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Swal from 'sweetalert2';
function SignIn() {
  const userRole = useSelector((state) => state.authentication.user?.id_role); // Accede al rol del usuario de manera segura
  const history = useHistory();
  const dispatch = useDispatch();
  const credentials = useSelector((state) => state.userInfo);
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated);
  const [input, setInput] = useState({
    email: '',
    password: '',
});

const [showPassword, setShowPassword] = useState(false);


const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
};


const submitHandler = async (e) => {
    e.preventDefault();

    
    if (!input.email) {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'El campo de correo electrónico es obligatorio.',
        });
        return;
    }

    if (!input.password) {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'El campo de contraseña es obligatorio.',
        });
        return;
    }

    
    if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(input.email)) {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'El correo electrónico no es válido.',
        });
        return;
    }

    
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(input.password)) {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'La contraseña debe tener al menos 8 caracteres, al menos una letra minúscula, al menos una letra mayúscula, al menos un número y al menos un carácter especial.',
        });
        return;
    }

    // Si no hay errores, continuar con el envío del formulario
    try {
        await login(input, dispatch, credentials, userInfo);
        if (isAuthenticated && userRole === 4) {
            history.push('/homePage');
        }
    } catch (error) {
        console.error('Se produjo un error:', error.message);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Hubo un problema al iniciar sesión.',
        });
    }
};


const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};

useEffect(() => {
    if (!isAuthenticated) {
        history.push('/');
    }
}, [isAuthenticated, history]);

return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <div>
            <form className="form-container" onSubmit={submitHandler}>
                <h2 className='form-title'>Iniciar sesión</h2>
                <label style={{ marginBottom: '16px' }}>
                    <input
                        type="email"
                        value={input.email}
                        name="email"
                        placeholder="Correo electrónico"
                        onChange={handleChange}
                        style={{ marginBottom: '16px' }}
                    />
                </label>

                <label style={{ marginBottom: '16px' }}>
                    <div className={style.passwordInput}>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            value={input.password}
                            name="password"
                            placeholder="Contraseña"
                            onChange={handleChange}
                            style={{ marginBottom: '16px' }}
                        />
                        {showPassword ? (
                            <VisibilityIcon
                                sx={{ fontSize: "medium", marginLeft: 1 }}
                                onClick={togglePasswordVisibility}
                            />
                        ) : (
                            <VisibilityOffIcon
                                sx={{ fontSize: "medium", marginLeft: 1 }}
                                onClick={togglePasswordVisibility}
                            />
                        )}
                    </div>
                </label>

                <Button
                    type="submit"
                    variant="contained"
                    style={{ marginTop: '20px' }}
                >
                    Ingresar
                </Button>
            </form>
        </div>
    </div>
);
}

export default SignIn;


