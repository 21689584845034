export const SIGNIN_USER = 'SIGNIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_USERS = "GET_USERS";
export const CLEAN_DETAIL = 'CLEAN_DETAIL';
export const CREATE_USER = "CREATE_USER";
export const FETCH_ASSIGNED_TASKS_REQUEST = 'FETCH_ASSIGNED_TASKS_REQUEST';
export const FETCH_ASSIGNED_TASKS_SUCCESS = 'FETCH_ASSIGNED_TASKS_SUCCESS';
export const FETCH_ASSIGNED_TASKS_FAILURE = 'FETCH_ASSIGNED_TASKS_FAILURE';
export const ASSIGN_TASK_TO_USER_REQUEST = 'ASSIGN_TASK_TO_USER_REQUEST';
export const ASSIGN_TASK_TO_USER_SUCCESS = 'ASSIGN_TASK_TO_USER_SUCCESS';
export const ASSIGN_TASK_TO_USER_FAILURE = 'ASSIGN_TASK_TO_USER_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const GET_TASKS_REQUEST = 'GET_TASKS_REQUEST';
export const GET_TASKS_FAILURE = 'GET_TASKS_FAILURE';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE';
export const CLEAN_CONTRACT_DETAILS = 'CLEAN_CONTRACT_DETAILS';
export const FETCH_CONTRACT_DETAILS_REQUEST = 'FETCH_CONTRACT_DETAILS_REQUEST';
export const FETCH_CONTRACT_DETAILS_SUCCESS = 'FETCH_CONTRACT_DETAILS_SUCCESS';
export const FETCH_CONTRACT_DETAILS_FAILURE = 'FETCH_CONTRACT_DETAILS_FAILURE';
export const GET_USER_INFO = 'GET_USER_INFO';
export const SET_ULTIMA_FACTURA= 'SET_ULTIMA_FACTURA';
export const SET_LAST_RESOLUCION= 'SET_LAST_RESOLUCION';
export const FETCH_ARTICULOS_SUCCESS = 'FETCH_ARTICULOS_SUCCESS';
export const FETCH_ARTICULOS_FAILURE = 'FETCH_ARTICULOS_FAILURE';
export const GET_TICKETS_REQUEST = 'GET_TICKETS_REQUEST';
export const GET_TICKETS_SUCCESS = 'GET_TICKETS_SUCCESS';
export const GET_TICKETS_FAILURE = 'GET_TICKETS_FAILURE';
export const GET_PROMOS_REQUEST = 'GET_PROMOS_REQUEST';
export const GET_PROMOS_SUCCESS = 'GET_PROMOS_SUCCESS';
export const GET_PROMOS_FAILURE = 'GET_PROMOS_FAILURE';
export const UPDATE_PROMO_STATUS_REQUEST = 'UPDATE_PROMO_STATUS_REQUEST';
export const UPDATE_PROMO_STATUS_SUCCESS = 'UPDATE_PROMO_STATUS_SUCCESS';
export const UPDATE_PROMO_STATUS_FAILURE = 'UPDATE_PROMO_STATUS_FAILURE';
export const GET_CONTRACT_PROMOS = 'GET_CONTRACT_PROMOS';
export const GET_CONTRACT_PROMOS_SUCCESS = 'GET_CONTRACT_PROMOS_SUCCESS';
export const GET_CONTRACT_PROMOS_FAILURE = 'GET_CONTRACT_PROMOS_FAILURE';

