import React, { useEffect, useState } from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { fetchAssignedTasks, deleteTask, getUsers } from "../../Redux/Actions/actions";
import { useSelector, useDispatch } from "react-redux";
import { Button, ButtonGroup } from "@mui/material";
import GiftIcon from '@mui/icons-material/CardGiftcard';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';

dayjs.locale("es");
dayjs.extend(localizedFormat);
const localizer = dayjsLocalizer(dayjs);

function Calendary() {
  const dispatch = useDispatch();
  const assignedTasks = useSelector((state) => state.assign.data?.assignments);
  const tasks = useSelector((state) => state.tasks);
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated);
  const userRole = useSelector((state) => state.authentication.user.id_role);

  const users = useSelector((state) => state.usersData);

  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [deleteTaskId, setDeleteTaskId] = useState(null);
  const [eventsCumple, setEventsCumple] = useState([]);
 
  useEffect(() => {
    dispatch(fetchAssignedTasks());
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    // Filtra los usuarios con roles 2, 3 y 4
    const filteredUsers = users.filter(user => [2, 3, 4].includes(user.id_role));
    
    const birthdayEvents = filteredUsers.map(user => {
        const dateOfBirth = dayjs(user.fecha_cumple);
        
        
        // Crea un evento para el cumpleaños
        return {
            id: `birthday-${user.n_documento}`, 
            title: `🎂${user.name_razonSocial}🎂`,
            start: dateOfBirth.set('year', dayjs().year()).toDate(), 
            end: dateOfBirth.set('year', dayjs().year()).toDate(),
            allDay: true, 
            resource: user, 
            isBirthday: true, 
        };
    });
    
    // Combina los eventos de cumpleaños con los eventos existentes
    setEventsCumple(birthdayEvents);
  }, [users]);

  const getNameRazonSocial = (n_documento) => {
    const user = users.find((user) => user.n_documento === n_documento);
    return user ? user.name_razonSocial : "";
  };

  const events = assignedTasks?.map((task) => {
    const start = new Date(`${task.taskDate}T${task.startTurno}`);
    const end = new Date(`${task.taskDate}T${task.endTurno}`);
    const selectedTaskObject = tasks.data.tasks.find((t) => t.taskId === task.taskId);
    const tareaName = selectedTaskObject ? selectedTaskObject.nameTask : "Tarea desconocida";
    const taskDescription = task.taskDescription || "No hay descripción"; 
 

    return {
      id: task.id,
      title: getNameRazonSocial(task.n_documento),
      start,
      end,
      turno: task.turno,
      tareaName: tareaName,
      taskDescription: taskDescription
    };
  }) || [];

  const combinedEvents = [...events, ...eventsCumple];

  const handleSelectSlot = ({ start, end }) => {
    setSelectedDay({ start, end });
    setSelectedTask(null);
  };

  const handleEventClick = (event) => {
    setSelectedTask(event);
  };

  const handleDeleteTask = async () => {
    if (deleteTaskId) {
      try {
        await dispatch(deleteTask(deleteTaskId));
        setDeleteTaskId(null);
        setSelectedTask(null);
        Swal.fire('Tarea eliminada', '', 'success');
      } catch (error) {
        console.error('Error deleting task:', error);
      }
    }
  };

 
  const eventContent = ({ event }) => {
    return (
      <div>
        <b>{event.title}</b>
        {event.isBirthday && (
          
          <GiftIcon />
        )}
        {event.turno && ` (${event.turno}) - ${event.tareaName}`}
      </div>
    );
  };

  // Función de estilo para eventos
  const eventPropGetter = (event) => {
    const style = {
      backgroundColor: 'primary', // Color predeterminado para eventos
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block',
    };

    
    if (event.isBirthday) {
      style.backgroundColor = 'red'; 
    }

    return {
      style: style,
    };
  };
  return (
    <div className="container">
    
      <ButtonGroup
        variant="contained"
        aria-label="Basic button group"
        style={{
          position: "fixed",
          top: "calc(100px + 20px)",
          left: "20px",
          zIndex: "999"
        }}
      >
        <Link to="/homePage" className="link">
          <Button style={{ margin: '10px' }}>Volver</Button>
        </Link>
      </ButtonGroup>


        
      <Calendar
        
        localizer={localizer}
        events={combinedEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, width: "auto" }}
        eventContent={eventContent}
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleEventClick}
        eventPropGetter={eventPropGetter}
        toolbar={{
          agenda: { eventHeight: 50 },
          hoy: { eventHeight: 50 },
          semana: { eventHeight: 50 },
          mes: { eventHeight: 50 },
          
        }}
        messages={{
          next: "Siguiente",
          previous: "Anterior",
          today: "Hoy",
          month: "Mes",
          week: "Semana",
          day: "Día",
          
        
        }}
      />
      {selectedTask && (
        <div className="user-table">
          <table>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Horario Inicial</th>
                <th>Horario Final</th>
                <th>Fecha</th>
                <th>Tarea asignada</th>
                <th>Descripción</th>
                <th>Eliminar tarea</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{selectedTask.title}</td>
                <td>{selectedTask.start.toLocaleTimeString()}</td>
                <td>{selectedTask.end.toLocaleTimeString()}</td>
                <td>{selectedTask.start.toLocaleDateString()}</td>
                <td>{selectedTask.tareaName}</td>
                <td>{selectedTask.taskDescription}</td>
                <td>
                  <Button onClick={() => setDeleteTaskId(selectedTask.id)}>Eliminar</Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {deleteTaskId && (
        <div>
          <p>¿Estás seguro de que deseas eliminar esta tarea?</p>
          <Button onClick={handleDeleteTask}>Confirmar Eliminación</Button>
          <Button onClick={() => setDeleteTaskId(null)}>Cancelar</Button>
        </div>
      )}
    </div>
  );
}

export default Calendary;
