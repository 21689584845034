import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getPromos, updatePromoStatus } from "../Redux/Actions/actions";
import { Button, ButtonGroup } from '@mui/material';
import { Link } from 'react-router-dom'

const ModificarPromo = () => {
    const dispatch = useDispatch();
    const promosData = useSelector((state) => state.promos);
    const promos = promosData.data; 
    const [localPromos, setLocalPromos] = useState([]);

    useEffect(() => {
        dispatch(getPromos());
    }, [dispatch]);

    useEffect(() => {
        setLocalPromos(promos); 
    }, [promos]);

    const handlePromoStatusChange = (promo_id, isActive) => {
        if (!isActive) {
            console.log(`Se ha desactivado la promoción con ID ${promo_id}`);
        }
        dispatch(updatePromoStatus(promo_id, isActive));

        const updatedPromos = localPromos.map(promo => {
            if (promo.promo_id === promo_id) {
                return { ...promo, isActive: !promo.isActive };
            }
            return promo;
        });
        setLocalPromos(updatedPromos);
    };

    return (
        <div className='container'>
            <ButtonGroup variant="contained" aria-label="Basic button group">
            <Link to="/homePage" className="link">
          <Button style={{ margin: '10px' }}>Volver</Button>
        </Link>
        <Link to="/comprobantes" className="link">
          <Button style={{ margin: '10px' }}>Nueva Promo</Button>
        </Link>
        </ButtonGroup>
            <h3 className="form-title" style={{ width: '100%', textAlign: 'center' }}>Modificar Promociones</h3>
            <div className="user-table">
            <table>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Descripción</th>
                        <th>Meses Descontados</th>
                        <th>Meses Pagos</th>
                        <th>Estado</th>
                        <th>Modificar</th>
                    </tr>
                </thead>
                <tbody>
                {Array.isArray(localPromos) && localPromos.length > 0 ? (
                    localPromos.map(promo => (
                        <tr key={promo.promo_id}>
                            <td>{promo.promo_name}</td>
                            <td>{promo.descripcion}</td>
                            <td>{promo.mesesDescontados}</td>
                            <td>{promo.mesesPagos}</td>
                            <td>{promo.isActive ? 'ACTIVA' : 'INACTIVA'}</td>
                            <td>
                                <Button style={{ margin: '10px' }} onClick={() => handlePromoStatusChange(promo.promo_id, !promo.isActive)}>
                                    {promo.isActive ? 'Desactivar' : 'Activar'}
                                </Button>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="5">No hay promociones disponibles</td>
                    </tr>
                )}
                </tbody>
            </table>
            </div>
           
        </div>
    );
};

export default ModificarPromo;
