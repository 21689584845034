import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import 'react-datepicker/dist/react-datepicker.css';
import { Button, ButtonGroup, TextField, CardContent, Typography,Grid,Card} from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, assignTaskToUser, getTasks } from "../Redux/Actions/actions";
import { makeStyles } from '@mui/styles';
import Swal from 'sweetalert2';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    height: '100px',
    margin: '10px',
    backgroundColor: '#1976d2', // Color de fondo azul
    color: '#ffffff', // Color de texto blanco
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 25,
    color: '#ffffff',
  },
  pos: {
    marginBottom: 12,
  },
  Button: {
    color: '#ffffff',
  },
  cardContent: {
    '& > * + *': {
      marginTop: '10px',
    },
  },
});

export default function TaskPage() {

  const [selectedUser, setSelectedUser] = useState("");
  const [selectedTask, setSelectedTask] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStart, setSelectedStart] = useState(null);
  const [selectedEnd, setSelectedEnd] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [assignedHours, setAssignedHours] = useState({});

  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.usersData);
  const classes = useStyles();
  const tasks = useSelector((state) => state.tasks);
  
  const filteredUsers = usersData.filter(user => user.id_role === 2 || user.id_role === 3);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getTasks());
  }, [dispatch]);

  const handleChangeUser = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleChangeTask = (event) => {
    setSelectedTask(event.target.value);
  };

  const handleTaskDescriptionChange = (event) => {
    setTaskDescription(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChangeStart = (value) => {
    setSelectedStart(dayjs(value)); 
  };

  const handleChangeEnd = (value) => {
    setSelectedEnd(dayjs(value)); 
  };


  const handleSubmit = (event) => {
    event.preventDefault();
  
    if (!selectedUser || !selectedTask || !selectedDate || !selectedStart || !selectedEnd || !taskDescription) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Para continuar debe llenar todas las casillas",
      });
      return;
    }
  
    const selectedUserObject = usersData.find(user => user.n_documento === selectedUser);
  console.log(selectedUserObject)
    if (!selectedUserObject) {
      console.error('Usuario no encontrado');
      return;
    }
  
    const formattedDate = selectedDate.format('YYYY-MM-DD'); // Formato YYYY-MM-DD
    const formattedStartTime = selectedStart.format('HH:mm'); // Formato HH:mm
    const formattedEndTime = selectedEnd.format('HH:mm'); // Formato HH:mm
  
    const formData = {
      taskId: selectedTask,
      n_documento: selectedUserObject.n_documento,
      startTurno: formattedStartTime,
      endTurno: formattedEndTime,
      taskDate: formattedDate,
      taskDescription,
    };
  
    console.log('Form Data:', formData);
    dispatch(assignTaskToUser(selectedTask, selectedUserObject.n_documento, formattedStartTime, formattedEndTime, formattedDate, taskDescription));
  
  const totalHours = selectedEnd.diff(selectedStart, 'hours');
  const oldTotalHours = selectedUserObject.WorkHours.reduce((acc, workHour) => acc + workHour.totalHours, 0);
  const newTotalHours = oldTotalHours + totalHours;
  
  setAssignedHours({
    ...assignedHours,
    [selectedUserObject.n_documento]: newTotalHours
  });
  
    setAlertMessage("Tarea asignada");
  
    Swal.fire({
      icon: "success",
      title: "Tarea asignada correctamente",
      text: "La tarea ha sido asignada al empleado exitosamente.",
    });
  
    setSelectedUser("");
    setSelectedTask("");
    setTaskDescription("");
    setSelectedDate(null);
    setSelectedStart(null);
    setSelectedEnd(null);
  
    setTimeout(() => {
      setAlertMessage("");
    }, 2000);
  };
  
  return (
    
    <Box 
    sx={{
      width:"100%",
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      zIndex: "999",
      '@media screen and (max-width: 768px)': {
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
    }}
    
    >
     
        <Box
      
      sx={{
        width: '50%',
        maxWidth: '800px',
        marginBottom: '20px',
        top:0,
        marginLeft:"30px"
      }}
    >
      
 {alertMessage && (
        <div className="alert">
          <span>{alertMessage}</span>
        </div>
      )}

      <form className="form-container" onSubmit={handleSubmit} style={{ width: "100%"}}>
       
        {usersData.length > 0 ? (
          <FormControl fullWidth >
            <InputLabel className='imput-label' id="employee-label">Elegir empleado</InputLabel>
            <Select className='imput-label'
              labelId="employee-label"
              value={selectedUser}
              onChange={handleChangeUser}
            >
              {usersData
                .filter((user) => user.id_role === 2 || user.id_role === 3 || user.id_role === 5)
                .map((user) => (
                  <MenuItem key={user.n_documento} value={user.n_documento}>
                    {user.name_razonSocial}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        ) : (
          <p>No hay empleados disponibles</p>
        )}

        {tasks.data && tasks.data.tasks.length > 0 ? (
          <FormControl fullWidth className="imput-label">
            <InputLabel className='imput-label' id="task-label">Elegir tarea</InputLabel>
            <Select className='imput-label'
              labelId="task-label"
              value={selectedTask}
              onChange={handleChangeTask}
            >
              {tasks.data.tasks.map((task) => (
                <MenuItem key={task.taskId} value={task.taskId}>
                  {task.nameTask}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <p>No hay tareas disponibles</p>
        )}
           <TextField
          fullWidth
          label="Descripción de la tarea"
          value={taskDescription}
          onChange={handleTaskDescriptionChange}
          margin="normal"
          variant="outlined"
          required
        />
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width:"100%" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              selected={selectedDate}
              onChange={handleDateChange}
              format="dd/MM/yyyy" // Formato de fecha
              placeholder="Selecciona una fecha" // Texto del placeholder 
            />
            <div style={{display: 'flex', flexDirection:"column"}}>
              <TimePicker
                label="Horario de ingreso"
                value={selectedStart}
                format="HH:mm"
                onChange={handleChangeStart}
              />
            
            
              <TimePicker
                label="Horario de egreso"
                value={selectedEnd}
                format="HH:mm"
                onChange={handleChangeEnd}
              />
            </div>


          </LocalizationProvider>
        </div>
        </div>
        <ButtonGroup className="imput-label">

          <Button type="submit">Asignar Tarea</Button>


        </ButtonGroup>
      </form>


    </Box>
  
    <Box
    style={{ position: "relative", top:0, left: "80px", right: "40px", bottom: "0", zIndex: "999" }}
      // className="button-table-container"
      // sx={{
      //   width: '50%',
      //   display: 'flex',
      //   flexDirection: 'column',
      //   alignItems: 'center',
      //   position:"relative"
      // }}
    >

      <ButtonGroup
       style={{display:"flex",flexDirection: 'column'}}
       className='button-group-container'
        variant="contained"
        aria-label="Basic button group"
       

      >
          <div> <Link to="/homePage">
          <Button style={{ margin: '10px' }}>Volver </Button>
        </Link></div>
        {/* <Link to="/calendario" >
          <Button style={{ margin: '10px' }}>Ver Calendario</Button>
        </Link>
        <Link to="/tycketera">
          <Button style={{ margin: '10px' }}>Tickets</Button>
        </Link> */}
       
        
      </ButtonGroup>
      <Grid item xs={12} sm={6} md={4}>
          <Link to="/calendario" className="link">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  CALENDARIO
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Link to="/tycketera" className="link">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  TICKETS
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
     
      {/* Renderizar la tabla de usuarios con roles 2 y 3 */}
      <div className="user-table" >

        <table style={{marginTop:"auto"}}>
          <thead>
            <tr>
              <th>Nombre/Razón Social</th>
              <th>Número de Documento</th>
              <th>Horas Asignadas</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map(user => (
              <tr key={user.n_documento}>
                <td>{user.name_razonSocial}</td>
                <td>{user.n_documento}</td>
                <td>{assignedHours[user.n_documento]}</td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>





    </Box>
  
    </Box>
  );
}
