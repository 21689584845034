import axios from "axios";
import React, { useState, useEffect } from "react";
import "../Facturacion/FormFactura.css";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik, Field, Form } from "formik";
import BASE_URL from "../../Config";
//import Swal from "sweetalert2";
import { MdSend } from "react-icons/md"; 
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import {
  getUsers,
  fetchLastBillNumber,
  fetchLastResolution,
  getUserByDocument,
  fetchArticulos,
  fetchContractDetails,
} from "../../Redux/Actions/actions";

import obtenerFechaActual from "../obtenerFechaActual";

function FacturacionManual() {
  const history = useHistory();
  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.usersData);
  const [selectedContract, setSelectedContract] = useState("");
  const lastInvoiceNumber = useSelector((state) => state.lastInvoiceNumber);
  const ultimaResolucionN = useSelector((state) => state.ultimaResolucion);
  const ultimaResolucionP = useSelector((state) => state.ultimaResolucion);
  const articulos = useSelector((state) => state.articulos);
  const [selectedSku, setSelectedSku] = useState("");
  const fechaActual = obtenerFechaActual();
  const { n_documento, n_contrato } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [clientLoaded, setClientLoaded] = useState(false);
  const [promo, setPromo] = useState(null);

  useEffect(() => {
    dispatch(fetchLastBillNumber());
    dispatch(fetchLastResolution());
    dispatch(getUsers());
    dispatch(getUserByDocument(n_documento));
    dispatch(fetchArticulos());

    if (n_contrato) {
      dispatch(fetchContractDetails(n_contrato));
    }
  }, [dispatch, n_documento, n_contrato]);

  useEffect(() => {
    if (ultimaResolucionN && ultimaResolucionP && lastInvoiceNumber) {
      setIsLoading(false);
    }
  }, [ultimaResolucionN, ultimaResolucionP, lastInvoiceNumber]);

  useEffect(() => {
    if (usersData && usersData.length > 0) {
      setClientLoaded(true);
    }
  }, [usersData]);

  const userInfo = useSelector((state) => state.userInfo);

  const numeroFacturaSiguiente = lastInvoiceNumber
    ? parseInt(lastInvoiceNumber) + 1
    : null;
  const clientData = usersData;
  const clientInfo = userInfo.data;
  const contrato = clientInfo.Contracts && clientInfo.Contracts[0];
  const userContracts = clientInfo.Contracts || [];
  const delivery = contrato && contrato.Delivery;
  const idStratus = contrato && contrato.idStratus;
  const municipio = delivery && delivery.municipio;
  const cliente = clientData.find(
    (cliente) => cliente.n_documento === n_documento
  );

  if (isLoading) {
    return <Grid>Cargando...</Grid>;
  }

  if (!cliente) {
    console.error("Cliente no encontrado para el documento:", n_documento);
    return <Grid>Cliente no encontrado</Grid>;
  }

  const determinarTaxLevelCode = (tipoPersona, idStratus) => {
    if (tipoPersona === "PERSONA_JURIDICA") {
      return "RESPONSABLE_DE_IVA";
    } else {
      if (idStratus >= 1 && idStratus <= 3) {
        return "NO_RESPONSABLE_DE_IVA";
      } else if (idStratus >= 4 && idStratus < 8) {
        return "RESPONSABLE_DE_IVA";
      } else {
        console.error("El valor de idStratus no está en el rango esperado.");
        return "";
      }
    }
  };

  const determinarTaxRate = (tipoPersona, idStratus) => {
    if (tipoPersona === "PERSONA_JURIDICA") {
      return 19;
    } else {
      if (idStratus >= 1 && idStratus <= 3) {
        return 0;
      } else if (idStratus >= 4 && idStratus < 8) {
        return 19;
      } else {
        console.error("El valor de idStratus no está en el rango esperado.");
        return 0;
      }
    }
  };

  if (!clientData) {
    console.log("clientData es undefined");
    return <Grid>Cargando...</Grid>;
  }
  const nombreMunicipio = delivery ? delivery.municipio : "";
  const getCodigoCiudad = (municipioNombre) => {
    const nombreNormalizado = municipioNombre.toUpperCase();
    const municipios = {
      RESTREPO: "606",
      CUMARAL: "226",
    };
    return municipios[nombreNormalizado] || "";
  };
  const codigoCiudad = getCodigoCiudad(nombreMunicipio);

  const getNombreApellido = (name_razonSocial, tipo_persona) => {
    if (tipo_persona === "PERSONA_JURIDICA") {
      return { nombre: "", apellido: name_razonSocial };
    } else if (tipo_persona === "PERSONA_NATURAL") {
      const [apellido, nombre] = name_razonSocial.split(", ");
      return { nombre, apellido };
    } else {
      return { nombre: "", apellido: "" };
    }
  };
  const { nombre, apellido } = getNombreApellido(
    cliente.name_razonSocial,
    cliente.tipo_persona
  );

  const handleSubmit = async (values, { setFieldValue }) => {
    const responses = await Promise.all(
      values.invoice.items.map(async (item) => {
        const dataToSend = {
          actions: values.actions,
          invoice: {
            ...values.invoice,
            items: [item],
          },
        };

        const response = await fetch("http://localhost:3001/facturaManual", {
          method: "POST",
          headers: {
            "Content-Type" : "application/json",
            "User-Agent": "application/json",
            "Auth-Token": "252fa305c4648059a2bcf9b22a53799c"

          },
          body: JSON.stringify(dataToSend),
        });

        return response.json();
      })
    );

    console.log("Respuestas del servidor:", responses);
  };
  const handlePreview = (values) => {
    console.log("Datos de previsualización:", values);
  };
  const handleAddItem = (values, setFieldValue) => {
    const newItems = [...values.invoice.items, createNewItem()];
    setFieldValue("invoice.items", newItems);
  };

  const handleContractChange = async (event, setFieldValue, values) => {
    const newContract = event.target.value;
    setSelectedContract(newContract);

    try {
      const response = await axios.get(
        `${BASE_URL}/promos/listar/${newContract}`
      );
      console.log("Respuesta de getContractPromos:", response);

      if (response?.data?.data?.length > 0) {
        const activePromo = response.data.data[0];
        console.log("Primera promoción:", activePromo);

        if (activePromo.isPromoActive && !activePromo.facturada) {
          setPromo({
            description: `Promoción Contrato ${newContract} Promo`,
            sku: activePromo.promo_id,
            price: parseFloat(activePromo.totalCost),
          });
          addPromoItem(activePromo, setFieldValue, values);
        }
      }
    } catch (error) {
      console.error("Error al obtener promociones del contrato:", error);
    }
  };

  const addPromoItem = (promo, setFieldValue, values) => {
    const newItem = {
      description: `Promoción Contrato ${selectedContract} Promo`,
      sku: promo.promo_id,
      price: parseFloat(promo.totalCost),
    };

    console.log("Nuevo artículo de promoción:", newItem);

    setFieldValue("invoice.items", [...values.invoice.items, newItem]);
  };

  const handleSkuChange = (event, setFieldValue, index) => {
    const selectedSku = event.target.value;
    setSelectedSku(selectedSku);
  
   
    if (promo && selectedSku === promo.sku?.toString()) {
      setFieldValue(`invoice.items[${index}].description`, promo.description);
      setFieldValue(`invoice.items[${index}].sku`, promo.sku);
      setFieldValue(`invoice.items[${index}].price`, promo.price);
    } else {
      const selectedArticulo = articulos.find(
        (articulo) => articulo.codigoDataico === selectedSku
      );
  
      if (selectedArticulo) {
        setFieldValue(
          `invoice.items[${index}].description`,
          `Contrato ${selectedContract} ${selectedArticulo.name_articulo}`
        );
        setFieldValue(
          `invoice.items[${index}].sku`,
          selectedArticulo.codigoDataico
        );
        setFieldValue(
          `invoice.items[${index}].price`,
          parseFloat(selectedArticulo.costo)
        );
      }
    }
  };
  
  const createNewItem = (isPromo, promo) => {
    if (isPromo && promo) {
      return {
        sku: promo.sku,
        quantity: 0,
        description: promo.description,
        price: promo.price,
        discount_rate: 0,
        taxes: [{ tax_category: "IVA", tax_rate: 19 }],
        retentions: [],
      };
    } else {
      return {
        sku: parseInt(selectedSku),
        quantity: 0,
        description: "",
        price: 0,
        discount_rate: contrato.descuento || "",
        taxes: [{ tax_category: "IVA", tax_rate: 19 }],
        retentions: [
          { tax_category: "RET_FUENTE", tax_rate: contrato.retencion || [] },
        ],
      };
    }
  };

  return (
    <Grid>
      <ButtonGroup
        variant="contained"
        aria-label="Basic button group"
        style={{
          position: "fixed",
          top: "calc(100px + 20px)",
          right: "20px",
          zIndex: "999",
        }}
      >
        <Link to="/homePage" className="link">
          <Button style={{ margin: "10px" }}>Volver</Button>
        </Link>
      </ButtonGroup>

      <Grid>
        <Formik
          initialValues={{
            actions: {
              send_dian: true,
              send_email: true,
              email: cliente.email,
            },
            invoice: {
              env: "PRODUCCION",
              dataico_account_id: "018ca7c0-c15a-84b1-9e4e-e36c76138d1b",
              number: numeroFacturaSiguiente,
              issue_date: fechaActual,
              payment_date: fechaActual,
              invoice_type_code: "FACTURA_VENTA",
              payment_means: "CASH",
              payment_means_type: "DEBITO",
              numbering: {
                resolution_number: ultimaResolucionN.number,
                prefix: ultimaResolucionP.prefijo,
                flexible: false,
              },
              customer: {
                email: cliente.email,
                phone: contrato ? contrato.tel1 : "",
                party_identification_type: cliente.tipo_documento,
                party_identification: cliente.n_documento,
                party_type: cliente.tipo_persona,
                tax_level_code: determinarTaxLevelCode(
                  cliente.tipo_persona,
                  idStratus
                ),
                tax_rate:determinarTaxRate(
                  cliente.tipo_persona,
                  idStratus
                ),
                regimen: "ORDINARIO",          
                company_name: cliente.name_razonSocial,
                first_name: nombre,
                family_name: apellido,
              },
              items: [
                {
                  sku: parseInt(selectedSku), //CODIGO DATAICO
                  quantity: 0,
                  description: "", //NAME_ARTICULO
                  price: 0, //COSTO
                  "discount-rate": contrato.descuento || "", //descuento
                  taxes: [{ tax_category: "IVA", tax_rate:determinarTaxRate(
                  cliente.tipo_persona,
                  idStratus
                ), }],
                retentions: contrato.retencion ? [
                {
                  tax_category: "RET_FUENTE",
                  tax_rate: contrato.retencion,
                },
              ] : [], 
                },
              ],
            },
          }}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className="form-containerFact" onSubmit={handleSubmit}>
              <h3 className="form-title">Carga manual nueva factura</h3>

              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                <Grid className="checkbox-container">
                  <label htmlFor="send_dian">Enviar a DIAN:</label>
                  <Checkbox
                    type="checkbox"
                    id="send_dian"
                    name="actions.send_dian"
                    readOnly
                  />
                </Grid>
                <Grid className="checkbox-container">
                  <label htmlFor="send_email">Enviar email:</label>
                  <Checkbox
                    type="checkbox"
                    id="send_email"
                    name="actions.send_email"
                    readOnly
                  />
                </Grid>
              </Grid>
              <Grid className="form-group">
                <Grid style={{ marginRight: 20 }}>
                  <label style={{ marginRight: 10 }} htmlFor="number">
                    Número:
                  </label>
                  <Field
                    type="text"
                    id="number"
                    name="invoice.number"
                    readOnly
                  />
                </Grid>
                <Grid style={{ marginRight: 20 }}>
                  <label style={{ marginRight: 10 }} htmlFor="issue_date">
                    Fecha:
                  </label>
                  <Field
                    type="text"
                    id="issue_date"
                    name="invoice.issue_date"
                    readOnly
                  />
                </Grid>
                <Grid style={{ marginRight: 20 }}>
                  <label style={{ marginRight: 10 }} htmlFor="payment_date">
                    Fecha vencimiento:
                  </label>
                  <Field
                    type="text"
                    id="payment_date"
                    name="invoice.payment_date"
                  />
                </Grid>
                <Grid>
                  <label
                    style={{ marginRight: 10 }}
                    htmlFor="invoice_type_code"
                  >
                    Factura tipo:
                  </label>
                  <Field
                    type="text"
                    id="invoice_type_code"
                    name="invoice.invoice_type_code"
                  />
                </Grid>
              </Grid>

              <Grid className="form-group">
                <Grid style={{ marginRight: 20 }}>
                  <label style={{ marginRight: 10 }} htmlFor="prefix">
                    Prefijo:
                  </label>
                  <Field
                    type="text"
                    id="prefix"
                    name="invoice.numbering.prefix"
                  />
                </Grid>
                <Grid style={{ marginRight: 20 }}>
                  <label
                    style={{ marginRight: 10 }}
                    htmlFor="resolution_number"
                  >
                    N° resolución:
                  </label>
                  <Field
                    type="text"
                    id="resolution_number"
                    name="invoice.numbering.resolution_number"
                  />
                </Grid>
                <Grid style={{ marginRight: 20 }}>
                  <label
                    style={{ marginRight: 10 }}
                    htmlFor="payment_means_type"
                  >
                    Tipo de pago:
                  </label>
                  <Field
                    type="text"
                    id="payment_means_type"
                    name="invoice.payment_means_type"
                  />
                </Grid>
              </Grid>

              <Grid className="form-group">
                <Grid style={{ marginRight: 20 }}>
                  <label style={{ marginRight: 10 }} htmlFor="email">
                    Email:
                  </label>
                  <Field
                    type="email"
                    id="email"
                    name="invoice.customer.email"
                  />
                </Grid>

                <Grid style={{ marginRight: 20 }}>
                  <label style={{ marginRight: 10 }} htmlFor="phone">
                    Teléfono:
                  </label>
                  <Field type="text" id="phone" name="invoice.customer.phone" />
                </Grid>

                <Grid style={{ marginRight: 20 }}>
                  <label
                    style={{ marginRight: 10 }}
                    htmlFor="party_identification"
                  >
                    Número de documento:
                  </label>
                  <Field
                    type="text"
                    id="party_identification"
                    name="party_identification"
                    value={n_documento}
                    readOnly
                  />
                  <select
                    value={selectedContract}
                    onChange={(event) =>
                      handleContractChange(event, setFieldValue, values, promo)
                    }
                  >
                    <option value="">Seleccionar Contrato</option>
                    {userContracts.map((contract) => (
                      <option
                        key={contract.n_contrato}
                        value={contract.n_contrato}
                      >
                        {contract.n_contrato}
                      </option>
                    ))}
                  </select>
                </Grid>
                <Grid style={{ marginRight: 20 }}>
                  <label style={{ marginRight: 10 }} htmlFor="party_type">
                    Tipo de persona:
                  </label>
                  <Field
                    type="text"
                    id="party_type"
                    name="invoice.customer.party_type"
                  />
                </Grid>
              </Grid>

              <Grid className="form-group">
                <Grid style={{ marginRight: 20 }}>
                  <label style={{ marginRight: 10 }} htmlFor="tax_level_code">
                    Código de nivel de impuestos:
                  </label>
                  <Field
                    type="text"
                    id="tax_level_code"
                    name="invoice.customer.tax_level_code"
                  />
                </Grid>

                <Grid style={{ marginRight: 20 }}>
                  <label style={{ marginRight: 10 }} htmlFor="regimen">
                    Régimen:
                  </label>
                  <Field
                    type="text"
                    id="regimen"
                    name="invoice.customer.regimen"
                  />
                </Grid>
                <Grid style={{ marginRight: 20 }}>
                  <label style={{ marginRight: 10 }} htmlFor="department">
                    Departamento:
                  </label>
                  <Field
                    type="text"
                    id="department"
                    name="invoice.customer.department"
                  />
                </Grid>
              </Grid>

              <Grid className="form-group">
                <Grid style={{ marginRight: 20 }}>
                  <label style={{ marginRight: 10 }} htmlFor="city">
                    Ciudad:
                  </label>
                  <Field
                    type="text"
                    id="city"
                    name="invoice.customer.city"
                    value={municipio}
                  />
                </Grid>
                <Grid style={{ marginRight: 20 }}>
                  <label style={{ marginRight: 10 }} htmlFor="address_line">
                    Dirección:
                  </label>
                  <Field
                    type="text"
                    id="address_line"
                    name="invoice.customer.address_line"
                  />
                </Grid>

                <Grid style={{ marginRight: 20 }}>
                  <label style={{ marginRight: 10 }} htmlFor="country_code">
                    Código de país:
                  </label>
                  <Field
                    type="text"
                    id="country_code"
                    name="invoice.customer.country_code"
                  />
                </Grid>
              </Grid>
              <Grid className="form-group">
                <Grid style={{ marginRight: 20 }}>
                  <label style={{ marginRight: 10 }} htmlFor="company_name">
                    Nombre de la empresa:
                  </label>
                  <Field
                    type="text"
                    id="company_name"
                    name="invoice.customer.company_name"
                  />
                </Grid>
                <Grid style={{ marginRight: 20 }}>
                  <label style={{ marginRight: 10 }} htmlFor="first_name">
                    Nombre:
                  </label>
                  <Field
                    type="text"
                    id="first_name"
                    name="invoice.customer.first_name"
                  />
                </Grid>

                <Grid style={{ marginRight: 20 }}>
                  <label style={{ marginRight: 10 }} htmlFor="family_name">
                    Apellido:
                  </label>
                  <Field
                    type="text"
                    id="family_name"
                    name="invoice.customer.family_name"
                  />
                </Grid>
              </Grid>

              <Grid className="form-group"></Grid>
              {values.invoice.items.map((item, index) => (
                <Grid key={index}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <span>Artículo {index + 1}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Card>
                        <CardHeader />
                        <CardContent style={{ marginTop: "5px" }}>
                          <Grid className="form-group">
                            <Grid style={{ marginRight: 20 }}>
                              <label
                                style={{ marginRight: 10 }}
                                htmlFor={`sku${index}`}
                              >
                                Código:
                              </label>
                              <select
                                id={`sku${index}`}
                                onChange={(event) =>
                                  handleSkuChange(event, setFieldValue, index)
                                }
                                value={item.sku}
                              >
                                <option value="">
                                  Selecciona un Código Dataico
                                </option>
                                {articulos.map((articulo) => (
                                  <option
                                    key={articulo.codigoDataico}
                                    value={articulo.codigoDataico}
                                  >
                                    {articulo.name_articulo}
                                  </option>
                                ))}
                                {promo && (
                                  <option key={promo.sku} value={promo.sku}>
                                    {promo.description} - ${promo.price}
                                  </option>
                                )}
                              </select>
                            </Grid>
                          </Grid>
                          <Grid className="form-group">
                            <Grid style={{ marginRight: 20 }}>
                              <label
                                style={{ marginRight: 10 }}
                                htmlFor={`quantity${index}`}
                              >
                                Cantidad:
                              </label>
                              <Field
                                type="number"
                                id={`quantity${index}`}
                                name={`invoice.items[${index}].quantity`}
                              />
                            </Grid>
                            <Grid style={{ marginRight: 20 }}>
                              <label
                                style={{ marginRight: 10 }}
                                htmlFor={`description${index}`}
                              >
                                Descripción:
                              </label>
                              <Field
                                type="text"
                                id={`description${index}`}
                                name={`invoice.items[${index}].description`}
                              />
                            </Grid>
                            <Grid style={{ marginRight: 20 }}>
                              <label
                                style={{ marginRight: 10 }}
                                htmlFor={`price${index}`}
                              >
                                Precio:
                              </label>
                              <Field
                                type="text"
                                id={`price${index}`}
                                name={`invoice.items[${index}].price`}
                              />
                            </Grid>
                          </Grid>
                          <Grid className="form-group">
                            <Grid style={{ marginRight: 20 }}>
                              <label
                                style={{ marginRight: 10 }}
                                htmlFor={`discount_rate${index}`}
                              >
                                Descuento:
                              </label>
                              <Field
                                type="text"
                                id={`discount_rate${index}`}
                                name={`invoice.items[${index}].discount_rate`}
                              />
                            </Grid>
                            <Grid style={{ marginRight: 20 }}>
                              <label
                                style={{ marginRight: 10 }}
                                htmlFor={`tax_category${index}`}
                              >
                                Categoría de Impuesto:
                              </label>
                              <Field
                                type="text"
                                id={`tax_category${index}`}
                                name={`invoice.items[${index}].taxes[0].tax_category`}
                              />
                            </Grid>
                            <Grid style={{ marginRight: 20 }}>
                              <label
                                style={{ marginRight: 10 }}
                                htmlFor={`tax_rate${index}`}
                              >
                                Porcentaje de Impuesto:
                              </label>
                              <Field
                                type="text"
                                id={`tax_rate${index}`}
                                name={`invoice.items[${index}].taxes[0].tax_rate`}
                              />
                            </Grid>
                          </Grid>
                          <Grid className="form-group">
                            <Grid style={{ marginRight: 20 }}>
                              <label
                                style={{ marginRight: 10 }}
                                htmlFor={`retention_tax_category${index}`}
                              >
                                Categoría de Retención:
                              </label>
                              <Field
                                type="text"
                                id={`retention_tax_category${index}`}
                                name={`invoice.items[${index}].retentions[0].tax_category`}
                              />
                            </Grid>
                            <Grid style={{ marginRight: 20 }}>
                              <label
                                style={{ marginRight: 10 }}
                                htmlFor={`retention_tax_rate${index}`}
                              >
                                Porcentaje de Retención:
                              </label>
                              <Field
                                type="text"
                                id={`retention_tax_rate${index}`}
                                name={`invoice.items[${index}].retentions[0].tax_rate`}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}

              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              >
                <ButtonGroup>
                  <Grid className="buttons-container">
                    <Button
                      type="button"
                      onClick={() => handleAddItem(values, setFieldValue)}
                    >
                      Agregar Item
                    </Button>

                    <Button type="submit"
                    onClick={() => handleSubmit(values, setFieldValue)}>
                <MdSend /> 
                    </Button> 
                    <Button
                      style={{ marginRight: 10, marginLeft: 10 }}
                      type="button"
                      onClick={() => history.push("/homePage")}
                    >
                      Volver
                    </Button>
                    <Button type="button" onClick={() => handlePreview(values)}>
                      Previsualizar
                    </Button>
                  </Grid>
                </ButtonGroup>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

export default FacturacionManual;



