import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, getTasks } from "../Redux/Actions/actions";
import { useHistory } from "react-router-dom";
//import Swal from 'sweetalert2';
import { Button, ButtonGroup } from '@mui/material';
import { Link } from 'react-router-dom'

export default function Worker() {

  const history = useHistory();
  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.usersData);
  
  const filteredUsers = Array.isArray(usersData)
        ? usersData.filter(user => user.id_role === 2 || user.id_role === 3 || user.id_role === 4)
        : [];
console.log(filteredUsers)
  useEffect(() => {
    dispatch(getUsers());
    dispatch(getTasks());
  }, [dispatch]);

  
  return (

    <div className='container'>
      <ButtonGroup variant="contained" aria-label="Basic button group">
        <Link to="/altaEmpleado" className="link">
          <Button style={{ margin: '10px' }}>Nuevo Empleado</Button>
        </Link>
        <Link to="/homePage" className="link">
          <Button style={{ margin: '10px' }}>Volver</Button>
        </Link>

      </ButtonGroup>
      <h3 className="form-title" style={{ width: '100%', textAlign: 'center' }}>Gestión empleados</h3>
      {/* Renderizar la tabla de usuarios con roles 2 y 3 */}
      <div className="user-table">

        <table>
          <thead>
            <tr>
              <th>Nombre/Razón Social</th>
              <th>Número de Documento</th>
              <th>Rol</th>
              <th>Editar</th>
              <td>Horas Asignadas</td>
            </tr>
          </thead>
          <tbody>
    {Array.isArray(filteredUsers) && filteredUsers.length > 0 ? (
        filteredUsers.map(user => (
            <tr key={user.n_documento ?? 'defaultKey'}>
                {/* Verifica si name_razonSocial existe, de lo contrario, muestra 'N/A' */}
                <td>{user.name_razonSocial ?? 'N/A'}</td>

                {/* Verifica si n_documento existe, de lo contrario, muestra 'N/A' */}
                <td>{user.n_documento ?? 'N/A'}</td>

                {/* Maneja los diferentes roles */}
                <td>
                    {user.id_role === 2 ? 'Técnico' :
                    user.id_role === 3 ? 'Asesor Comercial' :
                    user.id_role === 4 ? 'Administrador' : 'Otro Rol'}
                </td>

                {/* Botón para editar empleado */}
                <td>
                    <Link
                        to={{ pathname: '/modificarEmpleado', state: { n_documento: user.n_documento } }}
                        className="link"
                    >
                        <Button style={{ margin: '10px' }}>Editar Empleado</Button>
                    </Link>
                </td>

                {/* Muestra las horas de trabajo si existen, de lo contrario muestra 'N/A' */}
                <td>
                    {user.WorkHours && user.WorkHours.length > 0 ? user.WorkHours[0].totalHours : 'N/A'}
                </td>
            </tr>
        ))
    ) : (
        // Muestra un mensaje si no hay empleados cargados o con roles válidos
        <tr>
            <td colSpan={5}>No hay empleados cargados o con roles válidos.</td>
        </tr>
    )}
</tbody>

        </table>
      </div>







    </div>
  )
}
