import React from 'react'
import { Link, useHistory } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  Typography,
  Card,
  CardContent,
  Grid, Button, ButtonGroup
}
  from '@mui/material'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    height: '150px',
    margin: '10px',
    backgroundColor: '#1976d2', // Color de fondo azul
    color: '#ffffff', // Color de texto blanco
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 25,
    color: '#ffffff',
  },
  pos: {
    marginBottom: 12,
  },
  Button: {
    color: '#ffffff',
  },
  cardContent: {
    '& > * + *': {
      marginTop: '10px',
    },
  },
});

const theme = createTheme({
  typography: {
    fontSize: 18, 
  },
  palette: {
    primary: {
      main: '#1976d2', 
    },
    text: {
      primary: '#ffffff', 
    },
  },
});

export default function MovementsCash() {

  const history = useHistory();
  const classes = useStyles();



  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={3} style={{ marginTop: '100px' }}>
      <ButtonGroup
        variant="contained"
        aria-label="Basic button group"
        style={{
          position: "fixed",
          top: "calc(100px + 5px)",
          right: "20px",
          zIndex: "999",
          marginBottom: "50px"
        }}
      >
        <Link to="/homePage" className="link">
          <Button style={{margin: '10px'}}>Volver</Button>
        </Link>
      </ButtonGroup> 
        
        <Grid item xs={12} sm={6} md={4}>
          <Link to="/facturacion-masiva" className="link">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  FACTURACIÓN MASIVA
                </Typography>
                <Typography variant="body2" component="h3">
                  - Esta en construcción.
                  <br />
                </Typography>
              </CardContent>
              

            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Link to="/modificarPromo" className="link">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  PROMOCIONES
                </Typography>
                <Typography variant="body2" component="h3">
                  - Activar y Desactivar Promos.
                  <br />
                </Typography>
              </CardContent>
              

            </Card>
          </Link>
        </Grid>
       
        <Grid item xs={12} sm={6} md={4}>
          <Link to="/cargaFacturacion" className="link">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  CARGA F. DATAICO
                </Typography>
                <Typography variant="body2" component="h3">
                  - Carga de facturas Dataico.
                  <br />
                </Typography>
              </CardContent>

            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Link to="/movimientosCaja" className="link">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  MOVIMIENTOS DE CAJA
                </Typography>
                <Typography variant="body2" component="h3">
                  - Ver movimientos por fecha y cajero.
                  <br />
                </Typography>
              </CardContent>

            </Card>
          </Link>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}








