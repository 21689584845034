import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const Masiva = () => {
  const [loading, setLoading] = useState(false);

  const handleButtonClick = async () => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "Se enviará una solicitud de facturación masiva",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, enviar',
      cancelButtonText: 'Cancelar'
    });

    if (result.isConfirmed) {
      setLoading(true);

      try {
        const response = await axios.post('http://localhost:3001/factura');
        const { success, message, contratosFallidos } = response.data;

        setLoading(false);

        if (success) {
          let alertMessage = 'La solicitud de facturación masiva ha sido enviada exitosamente.';

          if (contratosFallidos.length > 0) {
            alertMessage += ` Sin embargo, algunos contratos no se pudieron facturar: ${contratosFallidos.join(', ')}`;
          }

          Swal.fire(
            '¡Enviado!',
            alertMessage,
            'success'
          );
        } else {
          Swal.fire(
            'Error',
            message,
            'error'
          );
        }
      } catch (error) {
        console.error('Error al generar la factura:', error);
        Swal.fire(
          'Error',
          'Hubo un error al generar la factura.',
          'error'
        );
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <button onClick={handleButtonClick}>
        Generar Factura
      </button>
      {loading && <p>Enviando la solicitud de facturación masiva, espere por favor...</p>}
    </div>
  );
};

export default Masiva;


