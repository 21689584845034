import {
  SIGNIN_USER,
  CREATE_USER,
  GET_USERS,
  CLEAN_DETAIL,
  LOGOUT_USER,
  FETCH_ASSIGNED_TASKS_REQUEST,
  FETCH_ASSIGNED_TASKS_SUCCESS,
  FETCH_ASSIGNED_TASKS_FAILURE,
  ASSIGN_TASK_TO_USER_REQUEST,
  ASSIGN_TASK_TO_USER_SUCCESS,
  ASSIGN_TASK_TO_USER_FAILURE,
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAILURE,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_REQUEST,
  GET_TASKS_REQUEST,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAILURE,
  FETCH_CONTRACT_DETAILS_FAILURE,
  FETCH_CONTRACT_DETAILS_REQUEST,
  FETCH_CONTRACT_DETAILS_SUCCESS,
  CLEAN_CONTRACT_DETAILS,
  GET_USER_INFO,
  SET_ULTIMA_FACTURA,
  SET_LAST_RESOLUCION,
  FETCH_ARTICULOS_SUCCESS,
  FETCH_ARTICULOS_FAILURE,
  GET_TICKETS_REQUEST,
  GET_TICKETS_SUCCESS,
  GET_TICKETS_FAILURE,
  GET_PROMOS_REQUEST,
  GET_PROMOS_SUCCESS,
  GET_PROMOS_FAILURE,
  UPDATE_PROMO_STATUS_REQUEST,
  UPDATE_PROMO_STATUS_SUCCESS,
  UPDATE_PROMO_STATUS_FAILURE,
  GET_CONTRACT_PROMOS, 
  GET_CONTRACT_PROMOS_SUCCESS, 
  GET_CONTRACT_PROMOS_FAILURE
} from "../Actions/actions-types";

const initialState = {
  authentication: {
    token: null,
    user: null,
    isAuthenticated: false,
  },
  userInfo: {},
  usersData: [],
  assign: {}, 
  tasks: [],

  lastInvoiceNumber:null,
  contractDetails:null,
  ultimaResolucion:null,
  articulos:[],
  loading: false,
  deleting:false,
  ticketsList: [],
  promos: [],
  contractPromo: [],
  updating: false,
  error: null, 
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN_USER:
      
      return {
        ...state,
        authentication: {
          token: action.payload.token,
          user: action.payload.user,
          isAuthenticated: true,
        },
        cashierName: action.payload.user.cashierName,
      };
      case LOGOUT_USER:
        return {
          ...state,
          authentication: {
            token: null,
            user: null,
            isAuthenticated: false,
          },
          userInfo: {}, 
        };
    case GET_USERS:
      
      return {
        ...state,
        usersData: action.payload,
      };
      case GET_USER_INFO:
    return {
        ...state,
        userInfo: action.payload,
    };
    case CREATE_USER:
      return {
        ...state,
        userInfo: action.payload,
      };
      case DELETE_TASK_REQUEST:
       
      return {
        ...state,
        deleting: true,
        error: null
      };
    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case DELETE_TASK_FAILURE:
      return {
        ...state,
        deleting: false,
        error: action.payload
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        updating: true,
        error: null
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updating: false,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.payload
      };
    case CLEAN_DETAIL:
      return {
        ...state,
        userInfo: {},
        loginError: false,
      };
      case FETCH_ASSIGNED_TASKS_REQUEST:
       
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ASSIGNED_TASKS_SUCCESS:
     
      return {
        ...state,
        assign: action.payload,
        loading: false,
      };
    case FETCH_ASSIGNED_TASKS_FAILURE:
     
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ASSIGN_TASK_TO_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ASSIGN_TASK_TO_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ASSIGN_TASK_TO_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case GET_TASKS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        tasks: action.payload // Actualiza el estado con las tareas obtenidas
      };
    case GET_TASKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
      case FETCH_CONTRACT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CONTRACT_DETAILS_SUCCESS:
      return {
        ...state,
        contractDetails: action.payload,
        loading: false,
      };
    case FETCH_CONTRACT_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
          case CLEAN_CONTRACT_DETAILS:
            return {
              ...state,
              contractDetails: null,
            };    
            
            case SET_ULTIMA_FACTURA:
              return {
                ...state,
                lastInvoiceNumber: action.payload
              };

              case SET_LAST_RESOLUCION:
              return {
                ...state,
                ultimaResolucion: action.payload
              };
              case FETCH_ARTICULOS_SUCCESS:
              return {
                ...state,
                articulos: action.payload,
                loading: false,
                error: null
               };
              case FETCH_ARTICULOS_FAILURE:
              return {
              ...state,
              loading: false,
               error: action.payload
              };
              case GET_TICKETS_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null
                };
              case GET_TICKETS_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  ticketsList: action.payload 
                };
              case GET_TICKETS_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.payload
                };
                case GET_PROMOS_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null
                };
              case GET_PROMOS_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  promos: action.payload 
                };
              case GET_PROMOS_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.payload
                };
                case UPDATE_PROMO_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case UPDATE_PROMO_STATUS_SUCCESS:
            const updatedPromos = state.promos.map(promo => {
                if (promo.promo_id === action.payload.promo_id) {
                    return { ...promo, isActive: action.payload.isActive };
                }
                return promo;
            });

            return {
                ...state,
                promos: updatedPromos,
                loading: false,
                error: null
            };
        case UPDATE_PROMO_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

            case GET_CONTRACT_PROMOS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_CONTRACT_PROMOS_SUCCESS:
            return {
                ...state,
                loading: false,
                promos: action.payload
            };
        case GET_CONTRACT_PROMOS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

    default:
      return state;
  }
};
export default rootReducer;