import React, { useEffect} from 'react';
import { getTickets } from '../../Redux/Actions/actions';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';



export default function Tycketera() {
   
    const dispatch = useDispatch();

   
    const ticketsList = useSelector((state) => state.ticketsList.data);
    console.log("vemos que trae tickets",ticketsList)
    
      useEffect(() => {
        dispatch( getTickets());
      }, [dispatch]);

      if (!ticketsList) {
        return <div>Cargando...</div>;
    }

   

  return (
    <TableContainer component={Paper}>
      <Table aria-label="ticket table">
        <TableHead>
          <TableRow>
            <TableCell>N° Ticket</TableCell>
            <TableCell>Tipo de Ticket</TableCell>
            <TableCell>Fecha/hora de Recepción</TableCell>
            <TableCell>Cerrado por</TableCell>
            <TableCell>Fecha/Hora de cierre</TableCell>
            <TableCell>Materiales recolectados</TableCell>
            <TableCell>Creado por</TableCell>
            <TableCell>Hora de apertura</TableCell>
            <TableCell>N° Documento</TableCell>
            <TableCell>Observaciones</TableCell>
            <TableCell>Telefono</TableCell>
            <TableCell>N° Contrato</TableCell>
            <TableCell>Poste</TableCell>
            <TableCell>Atendido por</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Materiales utilizados</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {ticketsList.map((ticket) => (
            <TableRow key={ticket.n_ticket}>
              <TableCell>{ticket.n_ticket}</TableCell>
              <TableCell>{ticket.ticket_type}</TableCell>
              <TableCell>{ticket.reception_datetime}</TableCell>
              <TableCell>{ticket.closed_by}</TableCell>
              <TableCell>{ticket.closing_datetime}</TableCell>
              <TableCell>{ticket.collected_materials}</TableCell>
              <TableCell>{ticket.created_by}</TableCell>
              <TableCell>{ticket.opening_datetime}</TableCell>
              <TableCell>{ticket.n_documento}</TableCell>
              <TableCell>{ticket.observations}</TableCell>
              <TableCell>{ticket.phone}</TableCell>
              <TableCell>{ticket.n_contrato}</TableCell>
              <TableCell>{ticket.poste}</TableCell>
              <TableCell>{ticket.served_by}</TableCell>
              <TableCell>{ticket.status}</TableCell>
              <TableCell>{ticket.used_materials}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};